import { time } from "@js/modules/time";

/**
 * @typedef {object} DeltaDateOptions
 * @property {boolean} [strict = true] Whether or not to use strict time interval (i.e. from Fix Point instead of start of X)
 */


/**
 * Default date manipulation options
 * @type {DeltaDateOptions}
 */
export const defaultDeltaDateOptions = Object.freeze({ strict: true });

/**
 * Get the current datetime
 * @returns {Date}
 */
export const now = () => new Date();

/**
 *
 * @param {Date} date
 * @returns {Object|{startOfDay(): Date, milliseconds(): *, clone(): Date, after(number, DeltaDateOptions=): Date}|Date|*}
 */
export const fromDate = date => {
	return {
		milliseconds(){
			return time.milliseconds(date.valueOf());
		},
		/**
		 * Clone the date object
		 * @returns {Date}
		 */
		clone(){
			return new Date(this.milliseconds());
		},
		/**
		 * Get date but at the start of day
		 * @returns {Date}
		 */
		startOfDay(){
			const d = this.clone();
			d.setHours(
				0, 0, 0, 0
			);
			return d;
		},
		/**
		 * Get the datetime from date after n milliseconds
		 * @param {number} n - The amount of milliseconds from now
		 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
		 * @returns {Date}
		 */
		addMilliseconds(n, options = defaultDeltaDateOptions){
			const d = options.strict ? date : this.startOfDay();
			return new Date(d.valueOf() + n);
		},
		/**
		 * Get the datetime from date minus n milliseconds
		 * @param {number} n
		 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
		 * @returns {Date}
		 */
		removeMilliseconds(n, options = defaultDeltaDateOptions){
			return this.addMilliseconds(-n, options);
		},
		/**
		 * Get next day from date
		 * @param {DeltaDateOptions} [options = {}] - The options for date computations
		 * @returns {Date}
		 */
		nextDay(options = defaultDeltaDateOptions){
			return this.addMilliseconds(time.days(1), options);
		},
		/**
		 * Get the day before date
		 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
		 * @returns {Date}
		 */
		dayBefore(options = defaultDeltaDateOptions){
			return this.removeMilliseconds(time.days(1), options);
		},
		/**
		 * Get the date a week from the given date
		 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
		 * @returns {Date}
		 */
		nextWeek(options = defaultDeltaDateOptions){
			return this.addMilliseconds(time.weeks(1), options);
		},
		/**
		 * Get the date a week prior to the given date
		 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
		 * @returns {Date}
		 */
		lastWeek(options = defaultDeltaDateOptions){
			return this.removeMilliseconds(time.weeks(1), options);
		},
		/**
		 * Determine whether or not the date is before the other date
		 * @param {Date} otherDate - The date to compare to
		 * @returns {boolean}
		 */
		isBefore(otherDate){
			return this.milliseconds() < fromDate(otherDate).milliseconds();
		},
	};
};

/**
 * Get the current datetime but at the start of day
 * @returns {Date}
 */
export const today = () => fromDate(now()).startOfDay();

/**
 * Get the datetime from now after n milliseconds
 * @param {number} n - The amount of milliseconds to add to now
 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
 * @returns {Date}
 */
export const after = (n, options = defaultDeltaDateOptions) => fromDate(now()).addMilliseconds(n, options);

/**
 * Get the datetime from now minus n milliseconds
 * @param {number} n - The amount of milliseconds to subtract from now
 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
 * @returns {Date}
 */
export const before = (n, options = defaultDeltaDateOptions) => fromDate(now()).removeMilliseconds(n, options);

/**
 * Get tomorrow's date
 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
 * @returns {Date}
 */
export const tomorrow = (options = defaultDeltaDateOptions) => fromDate(now()).nextDay(options);

/**
 * Get yesterday's date
 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
 * @returns {Date}
 */
export const yesterday = (options = defaultDeltaDateOptions) => fromDate(now()).dayBefore(options);

/**
 * Determine whether or not the given date is before now
 * @param {Date} date - The date to compare to
 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
 * @returns {boolean}
 */
export const isBeforeNow = (date, options = defaultDeltaDateOptions) => fromDate(date).isBefore(now());

/**
 * Determine whether or not the given date is before today
 * @param {Date} date - The date to compare to
 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
 * @returns {boolean}
 */
export const isBeforeToday = (date, options = defaultDeltaDateOptions) => fromDate(date).isBefore(today());

/**
 * Get next week's date
 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
 * @returns {Date}
 */
export const nextWeek = (options = defaultDeltaDateOptions) => fromDate(now()).nextWeek(options);

/**
 * Get last week's date
 * @param {DeltaDateOptions} [options = defaultDeltaDateOptions] - The options for date computations
 * @returns {Date}
 */
export const lastWeek = (options = defaultDeltaDateOptions) => fromDate(now()).lastWeek(options);
