import {
	Swiper,

	EffectFade,
	Controller,
	Pagination,
} from "swiper";
import { Breakpoints, currentBreakpointIs } from "@js/modules/breakpoints";

Swiper.use([
	EffectFade,
	Controller,
	Pagination,
]);


/**
 * Enable a navigation button
 * @param {Element|HTMLButtonElement} btn
 */
const enable = btn => {
	const disabledSvg = btn.querySelector("[data-disabled]");
	const activeSvg = btn.querySelector("[data-active]");

	if(disabledSvg && activeSvg){
		disabledSvg.classList.remove("active");
		disabledSvg.classList.add("hidden");
		activeSvg.classList.add("active");
		activeSvg.classList.remove("hidden");
	}

	btn.removeAttribute("disabled");
	btn.setAttribute("aria-disabled", "false");
};

/**
 * Disable a navigation button
 * @param {Element|HTMLButtonElement} btn
 */
const disable = btn => {
	const disabledSvg = btn.querySelector("[data-disabled]");
	const activeSvg = btn.querySelector("[data-active]");

	if(disabledSvg && activeSvg){
		disabledSvg.classList.add("active");
		disabledSvg.classList.remove("hidden");
		activeSvg.classList.remove("active");
		activeSvg.classList.add("hidden");
	}

	btn.setAttribute("disabled", "disabled");
	btn.setAttribute("aria-disabled", "true");
};

export const opinionsCarousel = () => {
	const carousel = document.querySelector(".m-view-opinions.swiper-container");

	if(!carousel)
		return;

	const swiper = new Swiper(carousel, {
		// the X.y instead of X for slidesPerView allows to see a bit of prev/next slides as well
		slidesPerView: 1.3,
		centeredSlidesBounds: true,
		spaceBetween: 20,
		// Using manual navigation (instead of built-in) as it's outside of the swiper-container (for styling)
		breakpoints: {
			[Breakpoints.medium]: {
				slidesPerView: 2.5,
				spaceBetween: 20,
			},
			[Breakpoints.large]: {
				slidesPerView: 2.5,
				spaceBetween: 32,
			},
			[Breakpoints.xlarge]: {
				slidesPerView: 3.5,
				spaceBetween: 32,
			},
		},
	});

	const nav = carousel.previousElementSibling;

	if(!(nav.matches(".swiper-navigation"))){
		console.error("[modules/carousel] Nav is not .swiper-navigation");
		return;
	}

	const prev = nav.querySelector(".swiper-button-prev");
	const next = nav.querySelector(".swiper-button-next");

	prev.addEventListener("click", e => {
		e.preventDefault();
		swiper.slidePrev();
	});

	next.addEventListener("click", e => {
		e.preventDefault();
		swiper.slideNext();
	});

	const onChange = ({ isBeginning, isEnd }) => {
		const forPrev = isBeginning ? disable : enable;
		const forNext = isEnd ? disable : enable;

		forPrev(prev);
		forNext(next);

		if(isBeginning && isEnd)
			nav.classList.add("swiper-navigation--hidden");
		else
			nav.classList.remove("swiper-navigation--hidden");
	};

	["slideChange", "resize"]
		.forEach(event => swiper.on(event, onChange));

	onChange(swiper);

	return swiper;
};

export const fundingFormCarousel = () => {
	const carousel = document.querySelector(".m-funding-form .swiper-container");

	if(!carousel)
		return;

	const swiper = new Swiper(carousel, {
		controller: {},
		centeredSlides: true,
		centeredSlidesBounds: true,
		autoHeight: true,
		allowTouchMove: false,
		followFinger: false,
		loop: false,
		slidesPerView: 1,
		effect: "fade",
		fadeEffect: { crossFade: true },
	});

	return swiper;
};


const renderBullet = steps => (index, className) => {
	const { key, step } = steps.item(index).dataset;

	return `
		<div class="m-form-progress__item ${className}" data-key="${key}">
			<div class="m-form-progress__item-number">${index + 1}</div>
			<div class="m-form-progress__item-text">${step}</div>
		</div>
	`;
};

export const fundingFormNavCarousel = steps => {
	const containerWrapper = document.querySelector(".m-form-progress");
	const carousel = containerWrapper.querySelector(".swiper-container");

	if(!carousel)
		return;

	const swiper = new Swiper(carousel, {
		loop: false,
		cssMode: true,
		pagination: {
			el: ".swiper-pagination",
			// type: "progressbar",
			renderBullet: renderBullet(steps),
		},

		slidesPerView: 1,
	});


	const prev = containerWrapper.querySelector(".m-form-progress__button--prev");
	const next = containerWrapper.querySelector(".m-form-progress__button--next");


	if(prev && next){
		swiper.on("slideChange", ({ isBeginning, isEnd }) => {
			const forPrev = isBeginning ? disable : enable;
			const forNext = isEnd ? disable : enable;

			forPrev(prev);
			forNext(next);
		});
	}

	return swiper;
};

export const setupAllCarousel = () => {
	opinionsCarousel();
	// fundingFormCarousel(); // is mounted in domain/funding/form/steps.js instead
	// fundingFormNavCarousel(); // is mounted in domain/funding/form/steps.js instead
};
