/**
 * Determine whether or not the Funding CTA should be displayed on this page
 * @return {boolean}
 */
import { isFundingPage } from "@js/modules/domain/funding/shared";


const shouldDisplayFundingCTA = () => {
	return !isFundingPage();
};

/**
 * Handle logic for the Funding CTA
 */
export const handleFundingCTA = () => {
	const cta = document.querySelector(".m-funding-cta");

	if(shouldDisplayFundingCTA())
		cta.classList.remove("m-funding-cta--hidden");
	else
		cta.remove();
};
