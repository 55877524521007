import { time } from "./time";
import { ls } from "./localStorage";
import { nextWeek } from "./date";

const delay = time.milliseconds(250); // Same duration as the CSS transition
const storageKey = "banner-closed";
const storageExpiration = () => nextWeek();

/**
 * @param {HTMLElement} banner
 */
const removeBanner = banner => {
	banner.remove();
};

/**
 * @param {HTMLElement} banner
 * @param {boolean} [shouldPersist] - Whether we should take into account the fact that the user closed the banner
 */
const closeBanner = (banner, shouldPersist = true) => {
	document.body.classList.remove("o-body--banner");
	banner.classList.add("m-banner--closed");
	banner.classList.remove("m-banner--visible");
	setTimeout(() => removeBanner(banner), delay);

	if(shouldPersist){
		ls.set(
			storageKey,
			true,
			{ expireAt: storageExpiration() }
		);
	}
};


export const setupTopBanner = () => {
	const banner = document.querySelector(".m-banner");
	if(!banner){
		/*
		Cleanup localStorage if the banner is not rendered
		because that means it's been disabled and may change in the future
		so we want to be sure to see it when we open it next time
		 */
		ls.remove(storageKey);
		return;
	}

	if(ls.has(storageKey)){
		removeBanner(banner);
		return;
	}

	const link = banner.querySelector(".m-banner__link");
	if(!link){
		console.error("[modules/topBanner @ setupTopBanner] link is null", banner);
		return;
	}

	const href = link.getAttribute("href");

	if(location.href.includes(href)){
		closeBanner(banner, false);
		return;
	}

	// eslint-disable-next-line no-shadow
	const close = banner.querySelector(".m-banner__close");
	if(!close){
		console.error("[modules/topBanner @ setupTopBanner] link is null", banner);
		return;
	}

	document.body.classList.add("o-body--banner");
	banner.classList.add("m-banner--visible");

	banner.addEventListener("click", e => {
		if(e.target === close || e.target === link)
			return;

		link.click();
	});

	link.addEventListener("click", e => {
		closeBanner(banner);
	});

	close.addEventListener("click", e => {
		e.preventDefault();
		closeBanner(banner);
	});
};
