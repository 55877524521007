import { qsGetOr } from "@js/modules/queryString";
import AnimateNumber from "@js/libs/AnimateNumber";
import { stepFromNum } from "@js/utils";

const SCORE_QS_KEY = "score";
const TOTAL_QS_KEY = "total";


export const isTestResultPage = () => {
	const main = document.querySelector("main");

	//TODO: Get the URLS
	return [
		"/resultat-auto",
		"/resulta-bateau",
		"/resultat-bateau",
		"/resultat-moto",
	].includes(location.pathname)
	|| [
		13, // auto
		14, // bateau
		15, // moto
	].some(nid => main.matches(`.o-main--${nid}`));
};

const DEFAULT_SCORE = 0;
const DEFAULT_TOTAL = 10;

export const setupAllTestResults = () => {
	if(!isTestResultPage())
		return;


	const score = parseInt(qsGetOr(SCORE_QS_KEY, DEFAULT_SCORE), 10);
	const total = parseInt(qsGetOr(TOTAL_QS_KEY, DEFAULT_TOTAL), 10);

	const result = document.querySelector(".m-circle");
	const scoreEl = result.querySelector("[data-score], .m-circle__score");
	const totalEl = result.querySelector("[data-total], .m-circle__total");

	const actualScore = isNaN(score) ? DEFAULT_SCORE : score;
	const actualTotal = isNaN(total) ? DEFAULT_TOTAL : total;

	scoreEl.dataset.num = `${actualScore}`;
	scoreEl.textContent = "0";
	// scoreEl.textContent = `${actualScore}`;
	totalEl.textContent = `${actualTotal}`;

	const animated = new AnimateNumber(scoreEl, {
		step: stepFromNum(actualScore),
		start: 0,
		formatter: Math.round,
		endFactory: () => actualScore,
	});

	animated.animate();
};
