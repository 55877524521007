/**
 * Delegate click to the given child
 * @param {Element} el - The element that will be clickable
 * @param {string} selector - The selector to the delegate clickable element
 * @param {boolean} [delegateInSubtree] - Whether or not the deleegate is in the subtree
 */
export const delegateClick = (
	el, selector, delegateInSubtree = true
) => {
	const delegate = delegateInSubtree ? el.querySelector(selector) : document.querySelector(selector);
	if(delegate === null){
		console.warn(
			"[modules/clickDelegate @ delegateClick] delegate is null",
			el,
			selector,
			delegateInSubtree
		);
		return;
	}

	el.classList.add("clickable");

	el.addEventListener("click", e => {
		if(e.target !== delegate){
			e.preventDefault();
			delegate.click();
			// triggerEvent("click", delegate);
		}
	});
};


const delegateCardsToButton = () => {
	const selectors = [
		[".o-block--permis .m-cards--permis .m-cards__card", ".a-btn"],
		[".m-cards--test .m-cards__card", ".a-btn"],
		// [],
	];

	selectors.forEach(([
		selector,
		delegateSelector,
		...args
	]) => {
		document.querySelectorAll(selector)
			.forEach(el => delegateClick(
				el, delegateSelector, ...args
			));
	});
};

export const setupAllClickDelegates = () => {
	delegateCardsToButton();
};
