/**
 * Determine whether or not the current page is the funding page
 * @return {boolean}
 */
export const isFundingPage = () => {
	return [
		"/financement",
		"/node/22",
	].includes(location.pathname)
		|| document.querySelector(".o-main--22") !== null;
};

/**
 * @typedef {object} FundingFormData
 * @property {string} permis
 * @property {number} age
 * @property {string} situation
 */

/**
 * @callback SteppinoHandler
 * @param {Element} step - The current step's element
 * @param {FormData} data - The form's data
 * @param {string} stepName - The current step's name
 * @param {Steppino} steppino - The steppino instance
 * @returns {boolean} Whether it should be accepted or not
 */

/**
 * @typedef SteppinoHandlerGroup
 * @property {SteppinoHandler} whenContinue - The continuation handler
 * @property {SteppinoHandler} whenCleanup - The cleanup handler
 */

/**
 * @callback SteppinoNavigationHook
 * @param {Element} step - The current step's element
 * @param {FormData} data - The form's data
 * @param {string} stepName - The current step's name
 * @param {Steppino} steppino - The steppino instance
 */

/**
 * @typedef {object} SteppinoSettings
 * @property {Element|HTMLFormElement} form - The form to cut into steps
 * @property {string} stepSelector - The CSS selector to the steps inside the form
 * @property {string[]} steps - The names of the steps
 * @property {SteppinoNavigationHook} navigationHook - A callback for when you navigate to a step
 */
