const blockSelector = ".o-block--list";
const pageAttribute = "data-page";
const decoHiddenClass = "o-block__decorations--hidden";
// o-block--list

//TODO: Make sure the URLs are correct
//TODO: Properly position these in the SCSS

const isHome = () => [
	"",
	"/",
].includes(location.pathname) || document.querySelector(".o-body--home") !== null;

const isPermisB = () => [
	"/permis-b",
	"/node/4",
].includes(location.pathname) || document.querySelector(".o-main--4") !== null;

const isPermisMoto = () => [
	"/permis-moto",
	"/node/11",
].includes(location.pathname) || document.querySelector(".o-main--11") !== null;

const isPermisBateau = () => [
	"/permis-plaisance",
	"/node/12",
].includes(location.pathname) || document.querySelector(".o-main--12") !== null;

const isAutoEcole = () => [
	"/etablissement-de-formation",
	"/node/24",
].includes(location.pathname) || document.querySelector(".o-main--24") !== null;

/**
 * Mapping that associate a predicate to the name of items to enable
 * @type {Map<function(): boolean, string>}
 */
const predicateMapping = new Map([
	[isHome, "home"],
	[isPermisB, "permis-b"],
	[isPermisMoto, "permis-moto"],
	[isPermisBateau, "permis-bateau"],
	[isAutoEcole, "autoecole"],
]);

export const handleDecorations = () => {
	const block = document.querySelector(blockSelector);

	if(block === null)
		return;

	/**
	 * The array of decoration pages to enable
	 * @type {string[]}
	 */
	const pages = (() => {
		const arr = [];

		// eslint-disable-next-line keyword-spacing
		for(const [predicate, page] of predicateMapping.entries()){
			if(predicate())
				arr.push(page);
		}

		return arr;
	})();

	pages.forEach(page => { // make these decoration visible
		const decorations = document.querySelectorAll(`.${decoHiddenClass}[${pageAttribute}="${page}"]`);
		decorations.forEach(deco => deco.classList.remove(decoHiddenClass));
	});
};
