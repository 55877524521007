import { time } from "@js/modules/time";
import { Passwordino } from "@js/libs/Passwordino";

const SVG_NS = "http://www.w3.org/2000/svg";
const SVG_XLINK = "http://www.w3.org/1999/xlink";

const decorator = () => {
	const wrapper = document.createElement("div");
	wrapper.classList.add("m-password");

	const svg = document.createElementNS(SVG_NS, "svg");
	svg.classList.add("m-password__icon");
	svg.setAttributeNS(
		null, "viewBox", "0 0 28 24"
	);
	const use = document.createElementNS(SVG_NS, "use");
	use.setAttributeNS(
		SVG_XLINK, "xlink:href", "#password-eye"
	);
	svg.appendChild(use);

	wrapper.appendChild(svg);

	return wrapper;
};

const wrapInPasswordino = input => {
	const passwordino = new Passwordino(input);
	const wrapper = passwordino.decorate(decorator);
	passwordino.hide();

	const clickTarget = wrapper.querySelector(".m-password__icon");
	if(!clickTarget){
		console.error(
			"[modules/domain/loginModal @ wrapInPasswordino] clickTarget is null", wrapper, input
		);
		return;
	}

	clickTarget.addEventListener("click", e => {
		e.preventDefault();
		passwordino.toggle();
	});

	return passwordino;
};

/**
 * @param {HTMLElement} loginModal
 */
const handleOrientationMessage = loginModal => {
	const activeClass = "show";
	const message = loginModal.querySelector(".m-modal__orientation");

	if(!message){
		console.warn("[modules/domain/loginModal @ handleOrientationMessage] message is null");
		return;
	}


	const isLandscape = () => {
		const defaultIsLandscape = window.innerWidth > window.innerHeight;

		if("orientation" in screen){
			const { type } = screen.orientation;

			return typeof type !== "undefined"
				? type.startsWith("landscape")
				: defaultIsLandscape;
		}
		else if("orientation" in window){
			return [
				90,
				270,
				-90,
			].includes(window.orientation);
		}

		return defaultIsLandscape;
	};

	const initiallyLandscape = isLandscape();

	if(initiallyLandscape)
		message.classList.add(activeClass);

	window.addEventListener("orientationchange", () => {
		if(isLandscape())
			message.classList.add(activeClass);
		else
			message.classList.remove(activeClass);
	});
};

const decorateLoadableForm = formWrapper => {
	if(!formWrapper)
		return;

	const form = formWrapper.querySelector("form");

	if(!form){
		setTimeout(() => decorateLoadableForm(formWrapper), time.milliseconds(500));
		return;
	}

	const input = form.querySelector("input:not([hidden]):not([type='hidden'])");
	if(input)
		input.dataset.autofocus = "true";

	const passwordInputs = form.querySelectorAll("[name='password'], [type='password']");
	passwordInputs.forEach(wrapInPasswordino);

	const anchor = formWrapper.querySelector("a");

	if(!anchor){
		console.warn("[modules/domain/loginModal @ decorateLoadableForm] anchor is null");
		return;
	}

	anchor.removeAttribute("style");
	anchor.classList.remove("a-btn");
	anchor.classList.add(
		"a-link",
		"a-link--grey",
		"a-link--underline",
		"a-link--no-left",
		"a-link--small"
	);
};

export const handleLoginModalDecoration = () => {
	const loginPageWrapper = document.getElementById("login-page-form");
	if(!loginPageWrapper){
		console.error("[modules/domain/loginModal @ handleLoginModalDecoration] loginPageWrapper is null");
		return;
	}

	decorateLoadableForm(loginPageWrapper);


	const loginModal = document.getElementById("login");

	if(!loginModal){
		console.error("[modules/domain/loginModal @ handleLoginModalDecoration] loginModal is null");
		return;
	}

	handleOrientationMessage(loginModal);

	decorateLoadableForm(loginModal);
};
