export const Permis = {
	SCOOTER: "scooter",
	AUTO: "voiture",
	GROUPE_LOURD: "lourd",
	MOTO: "moto",
	BATEAU: "bateau",
};

export const permisValues = new Set(Object.values(Permis));

export const Situation = {
	APPRENTISSAGE: "apprentissage",
	ETUDIANT: "etudiant",
	SALARIE: "salarie",
	DEMANDEUR_EMPLOI: "demandeur-emploi",
	DEMANDEUR_EMPLOI_RSA: "demandeur-emploi-rsa",
	INDEPENDANT: "independant",
	RESERVISTE: "reserviste",
	HANDICAP: "handicap",
	FORMATION_PRO: "contrat-formation-professionnelle",

	___ERROR_STATE: "___error-state", // used to bypass validation to allow to go in the error panel on error
};

export const situationsValues = new Set(Object.values(Situation));

export const Result = {
	COMPTE_PERSO_ACTIVITE: "compte-perso-activite",
	AIDE_POLE_EMPLOI: "aide-pole-emploi-financement",
	AIDE_POLE_EMPLOIE_SPE_LOURD: "aide-pole-emploi-spe-lourd",
	AIDES_LOCALES: "aides-locales", //FAJ
	AIDE_ETAT: "aide-etat",
	AIDE_RESERVISTE: "aide-reserviste",
	PERMIS_1E_PAR_JOUR: "permis-1e-par-jour",
	HANDICAP_GENERIQUE: "handicap-generique",
	HANDICAP_GROUPE_LOURD: "handicap-groupe-lourd",
	BOURSE: "bourse",
	MICROCREDIT: "micro-credit",
	CAF: "caf",

	SPECIAL__MOTO: "partiel-moto",
};

export const resultsValues = new Set(Object.values(Result));

export const Enums = {
	Permis,
	Situation,
	Result,
};
