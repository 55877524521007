import AnimateNumber from "@js/libs/AnimateNumber";
import { stepFromNum } from "@js/utils";

export const lazyload = (
	selector,
	onEnter,
	threshold = 0.1
) => {
	const options = {
		// root: null,
		rootMargin: "0px",
		threshold,
	};

	const onIntersect = (entries, observer) => {
		entries.forEach(({
			intersectionRatio,
			target,
		}) => {
			if(intersectionRatio > threshold){
				onEnter(target);
				observer.unobserve(target);
			}
		});
	};

	const observer = new IntersectionObserver(onIntersect, options);

	document.querySelectorAll(selector).forEach(el => observer.observe(el));

	return observer;
};

/**
 * Animate numbers on enter
 */
export const animateNumbers = () => {
	const numberClass = "animated-number";

	document
		.querySelectorAll(".m-cards--keys .m-cards__card .a-heading")
		.forEach(title => {
			title.innerHTML = title.textContent
				.replace(/(\d+)/g, function(_, numStr){
					const num = parseFloat(numStr);
					const step = stepFromNum(num);

					return `<span class="${numberClass}" data-step="${step}" data-num="${numStr}">${numStr}</span>`;
				});
		});

	const numberFormatter = new Intl.NumberFormat("fr-FR");

	lazyload(`.${numberClass}`, el => {
		const rawNum = el.dataset.num.trim();
		// eslint-disable-next-line no-shadow
		const { length } = numberFormatter.format(parseInt(rawNum, 10));
		const step = parseFloat(el.dataset.step) || 1;

		const anim = new AnimateNumber(el, {
			step,
			start: 0,
			delay: 50,
			formatter(num){
				const str = numberFormatter.format(num);
				const ret = str.padStart(length, "\u2002"); // EN space

				// double regular spaces due to font's weird space width
				return ret.replace(/\s/g, "\u00a0");
			},
		});

		anim.animate();
	});
};


export const setupAllLazyloading = () => {
	animateNumbers();
};
