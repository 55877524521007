/**
 * Get the error panel from the given step
 * @param {object} props
 * @param {Element} props.step - The step to get the error panel from
 * @param {string} props.errorMessage - The error message to use in the exception if not found
 * @returns {Element}
 * @throws {Error} If there's no error panel
 */
export const errorPanelFromStep = ({
	step,
	errorMessage,
}) => {
	const errorPanel = step.nextElementSibling;
	if(!errorPanel || !errorPanel.matches("[data-error-panel]"))
		throw new Error(errorMessage);

	return errorPanel;
};

/**
 * Setup the step with the error panel
 * @param {object} props
 * @param {Element} props.step
 * @param {string} props.errorLocation
 * @throws {Error} If there's no error panel
 */
export const withErrorPanel = ({ step, errorLocation } = {}) => {
	const errorPanel = errorPanelFromStep({
		step,
		errorMessage: `[modules/funding/form/steps @ ${errorLocation}] errorPanel is null, expected to have one`,
	});

	// Make the error panel active, hide the default panel
	step.setAttribute("hidden", "hidden");
	step.setAttribute("aria-hidden", "true");
	errorPanel.removeAttribute("hidden");
	errorPanel.setAttribute("aria-hidden", "false");
};

/**
 * Setup the step without the error panel
 * @param {object} props
 * @param {Element} props.step
 * @param {string} props.errorLocation
 * @throws {Error} If there's no error panel
 */
export const withoutErrorPanel = ({ step, errorLocation } = {}) => {
	const errorPanel = errorPanelFromStep({
		step,
		errorMessage: `[modules/funding/form/steps @ ${errorLocation}] errorPanel is null, expected to have one`,
	});

	// Make the default panel active, hide the error panel
	step.removeAttribute("hidden");
	step.setAttribute("aria-hidden", "false");
	errorPanel.setAttribute("hidden", "hidden");
	errorPanel.setAttribute("aria-hidden", "true");
};
