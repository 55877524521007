/**
 * @callback SteppinoSwiperController~thenCallback
 * @param {SteppinoSwiperController} controller
 * @returns {any}
 */

/**
 * @class SteppinoSwiperController
 */
export class SteppinoSwiperController{
	/**
	 * Construct a controller that syncs both the steppino and the swiper instances
	 * @param {Steppino} steppino
	 * @param {import("swiper").Swiper} swiper
	 */
	constructor(steppino, swiper){
		/**
		 * The steppino instance to control
		 * @type {Steppino}
		 * @readonly
		 */
		this.steppino = steppino;

		/**
		 * The swiper instance to control
		 * @type {import("swiper").Swiper}
		 * @readonly
		 */
		this.swiper = swiper;
	}

	/**
	 * @param {boolean} [shouldForce = false]
	 * @returns {boolean}
	 */
	moveForward(shouldForce = false){
		const didMove = this.steppino.moveForward(shouldForce);
		if(didMove)
			this.swiper.slideNext();

		return didMove;
	}

	/**
	 * @param {boolean} [shouldForce = false]
	 * @returns {boolean}
	 */
	moveBackward(shouldForce = false){
		const didMove = this.steppino.moveBackward(shouldForce);
		if(didMove)
			this.swiper.slidePrev();

		return didMove;
	}

	/**
	 * @param {number} index
	 * @returns {boolean}
	 */
	moveTo(index){
		const didMove = this.steppino.moveTo(index);
		if(didMove)
			this.swiper.slideTo(index);

		return didMove;
	}

	/**
	 * @returns {boolean}
	 */
	canMoveBackward(){
		return this.steppino.canMoveBackward();
	}

	/**
	 * @returns {boolean}
	 */
	canMoveForward(){
		return this.steppino.canMoveForward();
	}

	/**
	 * Execute a callback after the current navigation
	 * @param {SteppinoSwiperController~thenCallback} [callback] - An optional callback to call before moving
	 * @returns {SteppinoSwiperController}
	 */
	thenMoveForward(callback = () => {}){
		this.steppino.invokeAfterNextTransition(() => {
			callback(this);
			this.moveForward();
		});

		return this;
	}

	/**
	 * Execute a callback after the current navigation
	 * @param {SteppinoSwiperController~thenCallback} [callback] - An optional callback to call before moving
	 * @returns {SteppinoSwiperController}
	 */
	thenMoveBackward(callback = () => {}){
		this.steppino.invokeAfterNextTransition(() => {
			callback(this);
			this.moveBackward();
		});

		return this;
	}
}
