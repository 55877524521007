import { qsGet, qsHas } from "@js/modules/queryString";

const isTestPermisPage = () => [
	"/node/10",
	"/testez-vous",
].includes(location.pathname) || document.querySelector(".o-main--10") !== null;

const PermisType = {
	AUTO: "auto",
	MOTO: "moto",
	BOAT: "plaisance",
};

const permisTypes = new Set(Object.values(PermisType));

const itemClass = "m-cards__card--test";
const QUERY_STRING_PARAM = "permis";
const ATTR = "data-type";

const filterFromQuery = () => {
	if(!qsHas(QUERY_STRING_PARAM))
		return;

	const type = qsGet(QUERY_STRING_PARAM);

	if(!permisTypes.has(type)){
		console.warn(`[modules/domain/testPermis @ filterFromQuery] Invalid ${QUERY_STRING_PARAM} qs parameter: ${type}`);
		return;
	}

	const itemstoHide = document.querySelectorAll(`.${itemClass}[${ATTR}]:not([${ATTR}$="${type}"])`);

	itemstoHide.forEach(item => {
		[
			["hidden", "hidden"],
			["aria-hidden", "true"],
		].forEach(([attr, attrValue]) => item.setAttribute(attr, attrValue));

		item.style.display = "none";
	});
};

export const setupTestPermisPage = () => {
	if(!isTestPermisPage())
		return;

	filterFromQuery();
};
