import { time } from "@js/modules/time";
import { StepIndex } from "@js/modules/domain/funding/form/steps/constants";
import { sanitizeData } from "../validation";
import { computeResultList } from "../../constraints/results";
import { resultsValues, Situation } from "../../enums";
import {
	errorPanelFromStep, withErrorPanel, withoutErrorPanel,
} from "../errorPanel";
import { clearStateStorage } from "../persistence";
import { hideAlertsFor, showAlertsFor } from "../alerts";


const handleErrorPanelBackButton = step => {
	const errorPanel = errorPanelFromStep({
		step,
		errorMessage: "[modules/funding/form/steps @ handleResults] errorPanel is null, expected to have one",
	});

	const backButton = errorPanel.querySelector("[data-back]");

	if(!backButton){
		console.warn("[modules/funding/form/steps/handling/results @ handleErrorPanelBackButton] backButton is null");
		return;
	}

	backButton.addEventListener("click", e => {
		const isDisabled = backButton.hasAttribute("disabled") || backButton.getAttribute("aria-disabled") === "true";

		if(isDisabled)
			return;

		e.preventDefault();
		const btn = document.querySelector(".m-form-progress__button--prev");
		//TODO: Handle failure
		btn.click();
	});
};

/**
 *
 * @param {SteppinoSwiperController} controller
 * @param {object} elements
 * @param {Element} elements.resultsEl
 */
export const handleResults = (controller, { resultsEl }) => {
	const { steppino } = controller;
	const stepName = "results";
	const hiddenClass = "m-cards__card--hidden";

	handleErrorPanelBackButton(resultsEl);

	steppino.setStepWhenContinue("situation", (step, data) => {
		const saneData = sanitizeData(data);
		const resultNames = computeResultList(saneData).filter(name => resultsValues.has(name));

		if(resultNames.length === 0){ // There was an error
			withErrorPanel({
				step: resultsEl,
				errorLocation: "handleResults",
			});
		}
		else{ // There were no errors
			resultNames.forEach(name => {
				const result = resultsEl.querySelector(`[data-key="${name}"]`);

				if(result)
					result.classList.remove(hiddenClass);
			});
			// saveState(steppino); // only save state on success
		}

		window.dataLayer = window.dataLayer || [];
		window.dataLayer.push({ event: "FormSubmissionSuccess_funding" });

		showAlertsFor(saneData.permis);
		return true;
	});

	steppino.setStepWhenCleanup(stepName, (step, data) => {
		clearStateStorage();

		// Hide everything back
		resultsEl.querySelectorAll(`.m-funding-form__results-item:not(.${hiddenClass})`)
			.forEach(el => el.classList.add(hiddenClass));

		withoutErrorPanel({
			step,
			errorLocation: "handleResults",
		});

		hideAlertsFor(data.get("permis"));

		// If wants to go back in situation step but the selected value was the ERROR special placeholder value
		if(data.get("situation") === Situation.___ERROR_STATE && steppino.targetIndex === StepIndex.situation){
			// if skipped to result, go back once more as situation was never truly picked
			controller.thenMoveBackward();

			/*setTimeout(() => {
				const btn = document.querySelector(".m-form-progress__button--prev");
				//TODO: Handle failure

				btn.click();
			}, time.milliseconds(500));*/
		}

		return true;
	});
};
