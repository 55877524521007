import { keyPressedIsEnter, triggerEvent } from "@js/utils";
import { clearStateStorage } from "../persistence";

/**
 * Add Steppino specific handling for the age step
 * @param {SteppinoSwiperController} controller
 * @param {object} elements
 * @param {Element} elements.ageEl
 */
export const handleAge = (controller, { ageEl }) => {
	const { steppino } = controller;
	const stepName = "age";

	const input = ageEl.querySelector("input");
	const button = ageEl.querySelector(".m-funding-form__button .a-btn");
	const error = ageEl.querySelector(".m-funding-form__error");

	if(!input){
		console.error("[modules/domain/funding/form/steps @ handleAge] input is null", ageEl);
		return;
	}

	if(!button){
		console.error("[modules/domain/funding/form/steps @ handleAge] button is null", ageEl);
		return;
	}

	if(!error){
		console.error("[modules/domain/funding/form/steps @ handleAge]", ageEl);
		return;
	}



	input.addEventListener("keydown", e => {
		// On enter, submit value
		if(keyPressedIsEnter(e))
			button.click();
	});

	input.addEventListener("input", () => {
		const ariaDisabledValue = /^\s*$/.test(`${input.value}`) ? "true" : "false";
		button.setAttribute("aria-disabled", ariaDisabledValue);
	});

	button.addEventListener("click", e => {
		const isValid = input.checkValidity();

		if(!isValid){
			input.reportValidity();
			e.preventDefault();
		}
	});

	steppino.setStepWhenContinue(stepName, () => {
		const isValid = input.checkValidity();

		if(!isValid){
			input.reportValidity();
			return false;
		}

		input.blur();
		return true;
	});

	steppino.setStepWhenCleanup(stepName, () => {
		button.setAttribute("aria-disabled", "true");
		input.value = "";
		error.textContent = "";
		clearStateStorage();

		return true;
	});
};
