import {
	Permis,
	Result,
	Situation,
} from "@js/modules/domain/funding/form/enums";

//NB: See excel sheet for this

const ageLimit = 90; // included

/**
 * @param {object} payload
 * @param {number} payload.age
 * @param {string} payload.situation
 * @returns {string[]}
 */
const resultsForScooter = ({ age, situation }) => {
	if(age >= 18 && age <= 25 && situation === Situation.APPRENTISSAGE){
		return [
			Result.CAF,
			Result.AIDES_LOCALES,
			Result.BOURSE,
			Result.MICROCREDIT,
			Result.AIDE_ETAT,
		];
	}
	else if(age >= 14 && age <= 25 && situation === Situation.APPRENTISSAGE){
		return [
			Result.CAF,
			Result.BOURSE,
			Result.MICROCREDIT,
			Result.AIDE_ETAT,
		];
	}
	else if(age >= 14 && age <= 29 && situation === Situation.APPRENTISSAGE){
		return [
			Result.CAF,
			Result.BOURSE,
			Result.MICROCREDIT,
			// Result.AIDE_ETAT,
		];
	}
	else
		return [];
};

/**
 * @param {object} payload
 * @param {number} payload.age
 * @param {string} payload.situation
 * @returns {string[]}
 */
const resultsForAuto = ({ age, situation }) => {
	//TODO: Simplify this mess?
	if(age >= 15 && age <= 25 && situation === Situation.APPRENTISSAGE){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.AIDES_LOCALES,
			Result.AIDE_POLE_EMPLOI,
			Result.BOURSE,
			Result.MICROCREDIT,
			Result.PERMIS_1E_PAR_JOUR,
			Result.CAF,
		];
	}
	else if(age > 25 && age <= ageLimit && situation === Situation.APPRENTISSAGE)
		return [ Result.COMPTE_PERSO_ACTIVITE ];
	else if(age >= 15 && age <= 25 && situation === Situation.ETUDIANT)
		return [ Result.PERMIS_1E_PAR_JOUR ];
	else if(age >= 16 && age <= 25 && situation === Situation.SALARIE){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.PERMIS_1E_PAR_JOUR,
			Result.MICROCREDIT,
			Result.AIDES_LOCALES,
		];
	}
	else if(age > 25 && situation === Situation.SALARIE)
		return [ Result.COMPTE_PERSO_ACTIVITE ];
	else if(age >= 16 && age <= 25 && situation === Situation.DEMANDEUR_EMPLOI){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.AIDE_POLE_EMPLOI,
			Result.AIDES_LOCALES,
			Result.BOURSE,
			Result.MICROCREDIT,
			Result.PERMIS_1E_PAR_JOUR,
		];
	}
	else if(age > 25 && situation === Situation.DEMANDEUR_EMPLOI){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.AIDE_POLE_EMPLOI,
		];
	}
	else if(age >= 16 && age <= 25 && situation === Situation.DEMANDEUR_EMPLOI_RSA){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.AIDE_POLE_EMPLOI,
			Result.AIDES_LOCALES,
			Result.BOURSE,
			Result.MICROCREDIT,
			Result.PERMIS_1E_PAR_JOUR,
			Result.CAF,
		];
	}
	else if(age > 25 && situation === Situation.DEMANDEUR_EMPLOI_RSA){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.AIDE_POLE_EMPLOI,
			Result.BOURSE,
			Result.MICROCREDIT,
			Result.CAF,
		];
	}
	else if(age >= 16 && age <= 25 && situation === Situation.INDEPENDANT){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.PERMIS_1E_PAR_JOUR,
		];
	}
	else if(age > 25 && situation === Situation.INDEPENDANT)
		return [ Result.COMPTE_PERSO_ACTIVITE ];
	else if(age >= 17 && age <= 25 && situation === Situation.RESERVISTE){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.AIDE_RESERVISTE,
			Result.PERMIS_1E_PAR_JOUR,
		];
	}
	else if(age > 25 && age <= ageLimit && situation === Situation.RESERVISTE){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			// Result.AIDE_RESERVISTE,
		];
	}
	else if(age >= 15 && age <= 25 && situation === Situation.HANDICAP){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.PERMIS_1E_PAR_JOUR,
			Result.HANDICAP_GENERIQUE,
		];
	}
	else if(age > 25 && age <= ageLimit && situation === Situation.HANDICAP){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.HANDICAP_GENERIQUE,
		];
	}
	else
		return [];
};

/**
 * @param {object} payload
 * @param {number} payload.age
 * @param {string} payload.situation
 * @returns {string[]}
 */
const resultsForGroupeLourd = ({ age, situation }) => {
	if(age >= 18 && age <= 25 && situation === Situation.DEMANDEUR_EMPLOI){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.AIDE_POLE_EMPLOIE_SPE_LOURD,
			Result.AIDES_LOCALES,
			Result.BOURSE,
			Result.MICROCREDIT,
			// Result.CAF,
		];
	}
	else if(age >= 18 && age <= ageLimit){
		switch(situation){
		case Situation.SALARIE:
		case Situation.INDEPENDANT:
		case Situation.RESERVISTE:
			return [ Result.COMPTE_PERSO_ACTIVITE ];
		case Situation.DEMANDEUR_EMPLOI:
			return [
				Result.COMPTE_PERSO_ACTIVITE,
				Result.AIDE_POLE_EMPLOIE_SPE_LOURD,
				Result.BOURSE,
				Result.MICROCREDIT,
				// Result.CAF,
			];
		case Situation.DEMANDEUR_EMPLOI_RSA:
			return [
				Result.COMPTE_PERSO_ACTIVITE,
				Result.AIDE_POLE_EMPLOIE_SPE_LOURD,
				Result.BOURSE,
				Result.MICROCREDIT,
				Result.CAF,
			];
		case Situation.HANDICAP:
			return [
				Result.COMPTE_PERSO_ACTIVITE,
				Result.AIDE_POLE_EMPLOIE_SPE_LOURD,
				Result.HANDICAP_GROUPE_LOURD,
			];
		default:
			return [];
		}
	}

	return [];
};

/**
 * @param {object} payload
 * @param {number} payload.age
 * @param {string} payload.situation
 * @returns {string[]}
 */
const resultsForMoto = ({ age, situation }) => {
	const alwaysAvailable = [ Result.SPECIAL__MOTO ];

	if(age >= 16 && age <= 25 && situation === Situation.APPRENTISSAGE){
		return [
			Result.PERMIS_1E_PAR_JOUR,
			Result.COMPTE_PERSO_ACTIVITE,
			// Result.SPECIAL__MOTO,
		];
	}
	else if(age >= 16 && age <= 25 && situation === Situation.ETUDIANT){
		return [
			Result.PERMIS_1E_PAR_JOUR,
			// Result.SPECIAL__MOTO,
		];
	}
	else if(age >= 16 && age <= 25 && situation === Situation.SALARIE){
		return [
			Result.PERMIS_1E_PAR_JOUR,
			Result.COMPTE_PERSO_ACTIVITE,
			// Result.SPECIAL__MOTO,
		];
	}
	else if(age >= 16 && age <= 25 && situation === Situation.DEMANDEUR_EMPLOI){
		return [
			Result.PERMIS_1E_PAR_JOUR,
			Result.COMPTE_PERSO_ACTIVITE,
			Result.BOURSE,
			Result.MICROCREDIT,
			Result.AIDES_LOCALES,
			Result.AIDE_POLE_EMPLOI,
			// Result.SPECIAL__MOTO,
		];
	}
	else if(age >= 16 && age <= 25 && situation === Situation.DEMANDEUR_EMPLOI_RSA){
		return [
			Result.PERMIS_1E_PAR_JOUR,
			Result.COMPTE_PERSO_ACTIVITE,
			Result.BOURSE,
			Result.MICROCREDIT,
			Result.AIDES_LOCALES,
			Result.AIDE_POLE_EMPLOI,
			Result.CAF,
			// Result.SPECIAL__MOTO,
		];
	}
	else if(age >= 16 && age <= 25 && situation === Situation.INDEPENDANT){
		return [
			Result.PERMIS_1E_PAR_JOUR,
			// Result.SPECIAL__MOTO,
		];
	}
	else if(age >= 17 && age <= 25 && situation === Situation.RESERVISTE){
		return [
			Result.AIDE_RESERVISTE,
			Result.PERMIS_1E_PAR_JOUR,
			// Result.SPECIAL__MOTO,
		];
	}
	else if(age >= 16 && age <= 25 && situation === Situation.HANDICAP){
		return [
			Result.COMPTE_PERSO_ACTIVITE,
			Result.PERMIS_1E_PAR_JOUR,
			Result.HANDICAP_GENERIQUE,
			// Result.SPECIAL__MOTO,
		];
	}
	else
		return [];
};

/**
 * @param {object} payload
 * @param {number} payload.age
 * @param {string} payload.situation
 * @returns {string[]}
 */
const resultsForBateau = ({ age, situation }) => {
	if(age >= 16 && age <= ageLimit && situation === Situation.FORMATION_PRO)
		return [ Result.COMPTE_PERSO_ACTIVITE ];
	else
		return [];
};

/**
 * Compute the available results list
 * @param {FundingFormData} payload
 */
export const computeResultList = payload => {
	switch(payload.permis){
	case Permis.SCOOTER:
		return resultsForScooter(payload);


	case Permis.AUTO:
		return resultsForAuto(payload);


	case Permis.GROUPE_LOURD:
		return resultsForGroupeLourd(payload);

	case Permis.MOTO:
		return resultsForMoto(payload);

	case Permis.BATEAU:
		return resultsForBateau(payload);

	default:
		return [];
	}
};
