export const LOCAL_STORAGE_KEY = "funding-form-state";
export const QS_PERMIS_KEY = "permis";
export const QS_RESET_KEY = "reset";

export const StepIndex = {
	permis: 0,
	age: 1,
	situation: 2,
	results: 3,
};
