import { triggerEvent } from "@js/utils";
import { pickAndGo } from "../choices";
import { validateInputState } from "./validation";
import { StepIndex } from "./constants";

export const force = {
	/**
	 * Main logic for forcing a choice step
	 * @param {number} index
	 * @param {Steppino} steppino
	 * @param {string} value
	 * @returns {boolean} Whether it succeeded or not
	 */
	__handleForChoice(
		index, controller, value
	){
		const { steppino } = controller;
		const didMove = steppino.index === index || controller.moveTo(index);

		if(!didMove)
			return false;

		const el = steppino.getCurrentStepElement();
		const input = el.querySelector("input");

		if(!input)
			return false;

		const choices = el.querySelectorAll(".m-funding-form__choice");
		const choice = Array.from(choices).find(el => el.dataset.value === value);

		if(!choice)
			return false;

		const button = el.querySelector(".m-funding-form__button .a-btn");

		if(!button)
			return false;

		pickAndGo(
			choice, input, choices, button
		);

		return true;
	},

	/**
	 * Force the new permis state
	 * @param {SteppinoSwiperController} controller
	 * @param {string} value
	 * @returns {boolean} Whether it succeeded or not
	 */
	permis(controller, value){
		return this.__handleForChoice(
			StepIndex.permis,
			controller,
			value
		);
	},

	/**
	 * Force the new age state
	 * @param {SteppinoSwiperController} controller
	 * @param {number} value
	 * @returns {boolean} Whether it succeeded or not
	 */
	age(controller, value){
		const { steppino } = controller;
		const index = StepIndex.age;
		const didMove = steppino.index === index || controller.moveTo(index);

		if(!didMove)
			return false;

		const el = steppino.getCurrentStepElement();
		const input = el.querySelector("input");

		if(!input)
			return false;

		const button = el.querySelector(".m-funding-form__button .a-btn");

		if(!button)
			return false;

		input.value = `${value}`;
		triggerEvent("change", input);
		button.click();
		// triggerEvent("click", button);
		return true;
	},

	/**
	 * Force the new situation state
	 * @param {SteppinoSwiperController} controller
	 * @param {string} value
	 * @returns {boolean} Whether it succeeded or not
	 */
	situation(controller, value){
		return this.__handleForChoice(
			StepIndex.situation,
			controller,
			value
		);
	},
};

/**
 * @param {object} payload
 * @param {Element} payload.error
 * @param {HTMLInputElement|Element} payload.input
 * @param {SteppinoSwiperController} payload.controller
 * @returns {(function(Event): void)|*}
 */
export const goNext = ({
	error,
	input,
	controller,
}) => e => {
	e.preventDefault();

	validateInputState({
		input,
		error,
		onSuccess(){
			controller.moveForward();
		},
	});
};
