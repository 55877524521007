import { setImmediate } from "@js/utils";
import { Steppino } from "@js/libs/Steppino";
import {
	fundingFormCarousel,
	fundingFormNavCarousel,
} from "@js/modules/carousel";
import { time } from "@js/modules/time";
import { SteppinoSwiperController } from "@js/modules/domain/funding/form/SteppinoSwiperController";
import { StepIndex } from "@js/modules/domain/funding/form/steps/constants";
import { Situation } from "@js/modules/domain/funding/form/enums";
import { validateInputState } from "./validation";
import {
	clearStateStorage,
	restoreState,
} from "./persistence";
import { goNext } from "./manualIteration";
import { handlePermis } from "./handling/permis";
import { handleAge } from "./handling/age";
import { handleSituations } from "./handling/situation";
import { handleResults } from "./handling/results";


const scrollMultiplicator = progress => {
	switch(progress){
	case 0.25:
		return 0;
	case 0.75:
		return progress;
	case 0.5:
	default:
		return progress / 2;
	}
};

/**
 *
 * @param {Steppino} steppino
 */
const scrollStepNavIntoView = steppino => {
	const wrapper = document.querySelector(".m-form-progress__wrapper");
	if(!wrapper){
		console.error("[modules/domain/funding/form/steps @ scrollStepNavIntoView] wrapper is null");
		return;
	}


	const progress = steppino.getProgress();
	wrapper.scrollLeft = wrapper.scrollWidth * scrollMultiplicator(progress);
};

/**
 * @type {SteppinoNavigationHook}
 */
const scrollStepIntoView = (
	step,
	data,
	stepName,
	steppino
) => {
	const doNav = () => {
		const header = step.matches("[hidden]")
			? step.nextElementSibling.querySelector("[id]")
			: step.querySelector("[id]");

		history.pushState(
			{ stepName },
			null,
			`#${header.id}`
		);

		setImmediate(() => header.scrollIntoView({ block: "nearest" }));
	};

	doNav();

	setTimeout(() => scrollStepNavIntoView(steppino), time.milliseconds(500));
};

/**
 * @param {SteppinoSwiperController} controller
 */
const handleValidationAndSubmission = controller => {
	controller.steppino.stepElements.forEach(el => {
		if(el.matches(".m-funding-form__results"))
			return; // do not handle results page

		const button = el.querySelector(".m-funding-form__button .a-btn");
		if(!button){
			console.error("[modules/funding/form/steps @ syncSteppinoWithSwiper] button is null");
			return;
		}

		const input = el.querySelector("input");
		if(!input){
			console.error("[modules/funding/form/steps @ syncSteppinoWithSwiper] input is null");
			return;
		}

		const error = el.querySelector(".m-funding-form__error");
		if(!error){
			console.error("[modules/funding/form/steps @ syncSteppinoWithSwiper] error is null");
			return;
		}

		input.addEventListener("change", e => {
			error.textContent = "";
		});

		button.addEventListener("click", goNext({
			input,
			error,
			controller,
		}));
	});
};

/**
 *
 * @param {Steppino} steppino
 * @param {import("swiper").Swiper} mainSwiper
 * @param {import("swiper").Swiper} navSwiper
 * @param {SteppinoSwiperController} controller
 */
function attachNavToMainSwiper(
	steppino, mainSwiper, navSwiper, controller
){
	mainSwiper.controller.control = navSwiper;
	const wrapperContainer = navSwiper.el.parentElement;

	const prev = wrapperContainer.querySelector(".m-form-progress__button--prev");
	const next = wrapperContainer.querySelector(".m-form-progress__button--next");

	if(!(prev && next))
		return;


	// Manual navigation handling
	prev.addEventListener("click", e => {
		e.preventDefault();
		if(prev.hasAttribute("disabled"))
			return;

		// No need to validate here
		const didMove = controller.moveBackward();

		if(didMove)
			clearStateStorage();
			// steppino.__triggerNavigationHook(); // force hook replay (needed for some reason)
	});

	next.addEventListener("click", e => {
		if(next.hasAttribute("disabled")){
			e.preventDefault();
			return;
		}

		const { activeIndex, slides } = mainSwiper;
		const slide = slides[activeIndex];

		const input = slide.querySelector("input");
		const error = slide.querySelector(".m-funding-form__error");

		goNext({
			controller,
			input,
			error,
		})(e);
	});
}



/**
 *
 * @param {Steppino} steppino
 * @param {import("swiper").Swiper} navSwiper
 */
const handleSwiperProgressUpdate = (steppino, navSwiper) => {
	const bar = navSwiper.el.querySelector(".m-form-progress__bar-content");
	if(!bar){
		console.error("[modules/funding/form/steps @ handleSwiperProgressUpdate] bar is null");
		return;
	}

	/**
	 * @param {import("swiper").Swiper} swiper
	 */
	const onProgress = ({ activeIndex, slides }) => {
		const progress = Math.min(1, (activeIndex + 1) / slides.length);

		const newWidth = (() => {
			if(isNaN(progress) || progress < 0)
				return "0";
			if(progress > 0.75)
				return "100%";
			else
				return `${Math.ceil(progress * 100) - 12.5}%`; // minus 12..5 to "center" behind the point
		})();

		bar.style.width = newWidth;
	};

	navSwiper.on("slideChange", onProgress);
	onProgress(navSwiper);
};

/**
 *
 * @param {Steppino} steppino
 * @returns {{navSwiper: Swiper, swiper: Swiper}}
 */
const handleSwiper = steppino => {
	const swiper = fundingFormCarousel(); // from modules/carousel.js
	const controller = new SteppinoSwiperController(steppino, swiper);
	handleValidationAndSubmission(controller);



	const navSwiper = fundingFormNavCarousel(steppino.stepElements);
	attachNavToMainSwiper(
		steppino, swiper, navSwiper, controller
	);

	handleSwiperProgressUpdate(steppino, navSwiper);

	return {
		swiper,
		navSwiper,
		controller,
	};
};

/**
 * Create the navigation handler above the form
 * @param {Steppino} steppino
 */
const createNavigationHandler = steppino => {
	const slidesHolder = document.querySelector(".m-form-progress__content");

	steppino.stepElements.forEach(() => {
		const item = document.createElement("div");
		item.classList.add("swiper-slide");
		slidesHolder.appendChild(item);
	});
};

/**
 *
 * @param {Steppino} steppino
 * @param {import("swiper").Swiper} swiper
 * @param {import("swiper").Swiper} navSwiper
 * @param {SteppinoSwiperController} controller
 */
const clickableNavHandlers = (
	steppino, swiper, navSwiper, controller
) => {
	const items = navSwiper.el.querySelectorAll(".m-form-progress__item");
	items.forEach((item, i) => {
		item.addEventListener("click", e => {
			e.preventDefault();

			/*if(belowBreakpoint(Breakpoints.medium))
				return; // Do not make them clickable on medium*/

			const slide = swiper.slides[swiper.activeIndex];
			const isResultsSlide = slide.matches(".m-funding-form__results") || slide.querySelector(".m-funding-form__results") !== null;
			const input = slide.querySelector("input");
			const error = slide.querySelector(".m-funding-form__error");

			if(!input && !isResultsSlide){
				console.error("[modules/funding/form/steps @ clickableNavHandlers] input is null");
				return;
			}

			if(!error && !isResultsSlide){
				console.error("[modules/funding/form/steps @ clickableNavHandlers] error is null");
				return;
			}

			const doMove = () => {
				const shouldShortCircuit =
					i === StepIndex.situation // Want to go to the situation step
					&& controller.steppino.getData().get("situation") === Situation.___ERROR_STATE // But was skipped to go to error panel
					&& controller.steppino.index === StepIndex.results; // And we come from the results

				const index = shouldShortCircuit ? i - 1 : i;

				const didMove = controller.moveTo(index);
				// const didMove = steppino.moveTo(i);

				if(didMove){
					// swiper.slideTo(i);
					if(index < steppino.steps.length - 1)
						clearStateStorage();
				}
			};

			if(i > steppino.index && !isResultsSlide){ // Needs to validate to move forward
				validateInputState({
					input,
					error,
					onSuccess: doMove,
				});
			}
			else
				doMove();
		});
	});
};

/**
 * Hijack the browser's navigation to navigate back
 * @param {SteppinoSwiperController} controller
 */
const hijackBrowserNavigation = controller => {
	window.addEventListener("popstate", e => {
		if(!controller.canMoveBackward())
			return;

		const { state } = e;
		const isNavState = !!state && ["stepName"].every(key => key in state);

		if(!isNavState)
			return;

		// e.preventDefault();
		// e.stopPropagation();

		controller.moveBackward();
		// history.back();
	});
};

export const handleSteps = form => {
	const steppino = new Steppino({
		form,
		stepSelector: ".m-funding-form__step",
		steps: [
			"permis",
			"age",
			"situation",
			"results",
		],
		navigationHook: scrollStepIntoView,
	});

	const permisEl = steppino.getStepElementByName("permis");
	const ageEl = steppino.getStepElementByName("age");
	const situationsEl = steppino.getStepElementByName("situation");
	const resultsEl = steppino.getStepElementByName("results");

	const elements = {
		permisEl,
		ageEl,
		situationsEl,
		resultsEl,
	};

	createNavigationHandler(steppino);
	const {
		swiper,
		navSwiper,
		controller,
	} = handleSwiper(steppino);
	clickableNavHandlers(
		steppino, swiper, navSwiper, controller
	);

	handlePermis(controller, elements);
	handleAge(controller, elements);
	handleSituations(controller, elements);
	handleResults(controller, elements);

	restoreState(controller);
	hijackBrowserNavigation(controller);
};
