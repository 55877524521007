/**
 * @class Detector
 * @classdesc A class used for JS browser/platform detection
 */
class BrowserDetector{
	constructor(global = window){
		this.global = global;
		this.navigator = this.global.navigator;
		this.ua = this.navigator.userAgent.toLowerCase();
		this.vendor = this.navigator.vendor.toLowerCase() || "";
		this.platform = this.navigator.platform.toLowerCase();

		this.bootstrapped = false;
	}

	// CSS Setup
	bootstrapClasses(){
		if(this.bootstrapped)
			return this;

		const html = this.global.document.documentElement;

		[
			//Browsers
			"ie",
			"edgeChromium",
			"edge",
			"chrome",
			"opera",
			"firefox",
			"safari",
			"vivaldi",

			//Specific Browsers
			"chromeIOS",
			"ieMobile",

			//Platforms
			"windows",
			"mac",
			"linux",
			"android",
			"blackberry",
			"ios",

			//Type
			"desktop",
			"mobile",

			//Devices
			"ipad",
			"ipod",
			"iphone",

			// Implementations
			"chromiumBased",
		].forEach(method => {
			if(this[method]())
				html.classList.add(method);
		});

		return this;
	}

	// Pure browsers
	ie(){
		return (
			this.ua.includes("msie")
			|| this.ua.includes("trident")
		) && !this.opera();
	}

	edge(){
		return this.ua.includes("edge");
	}

	edgeChromium(){
		return !this.edge() && [
			"edg",
			"chrome",
		].every(str => this.ua.includes(str));
	}

	chrome(){
		return this.ua.includes("chrome")
			&& this.vendor.includes("google")
			&& !this.opera()
			//&& !this.safari()
			&& !this.vivaldi()
			&& !this.edgeChromium();
	}

	opera(){
		return typeof this.global.opr !== "undefined";
	}

	firefox(){
		return this.ua.includes("firefox");
	}

	safari(){
		return this.ua.includes("safari")
			&& !this.vivaldi()
			&& !this.chrome()
			&& !this.edge()
			&& !this.edgeChromium();
	}

	vivaldi(){
		return this.ua.includes("vivaldi");
	}


	// Specific browsers
	chromeIOS(){
		return this.ua.includes("crios");
	}

	ieMobile(){
		return this.ua.includes("iemobile");
	}

	// Platform
	windows(){
		return this.platform.includes("win");
	}

	mac(){
		return this.platform.includes("mac");
	}

	linux(){
		return this.platform.includes("linux");
	}

	android(){
		return this.ua.includes("android");
	}

	ios(){
		return /ip(hone|ad|od)/i.test(this.ua);
	}

	blackberry(){
		return this.ua.includes("blackberry");
	}

	// Type
	desktop(){
		return !this.mobile();
	}

	mobile(){
		return [
			"chromeIOS",
			"ieMobile",

			"android",
			"ios",
			"blackberry",
		].some(k => this[k]());
	}

	// Devices
	ipad(){
		return this.ios() && this.ua.includes("ipad");
	}

	iphone(){
		return this.ios() && this.ua.includes("iphone");
	}

	ipod(){
		return this.ios() && this.ua.includes("ipod");
	}

	// Implementations
	chromiumBased(){
		return this.chrome()
			|| this.edgeChromium()
			|| this.safari()
			|| this.opera()
			|| this.vivaldi();
	}
}

const makeBrowserDetector = (global = window) => new BrowserDetector(global);

export { makeBrowserDetector };
