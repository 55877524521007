import { keyPressedIsEnter, triggerEvent } from "@js/utils";

const activeClass = "m-cards__card--active";

/**
 * @param {Element} choice
 * @param {Element|HTMLInputElement} input
 * @param {Iterable<Element>|NodeListOf<Element>} choices
 */
export const unpickChoice = (
	choice,
	input,
	choices
) => {
	choice.classList.remove(activeClass);
	input.value = "";
	triggerEvent("change", input);
};

/**
 * @param {Element} choice
 * @param {Element|HTMLInputElement} input
 * @param {Iterable<Element>|NodeListOf<Element>} choices
 */
export const pickChoice = (
	choice,
	input,
	choices
) => {
	const { value } = choice.dataset;
	choices.forEach(c => { // cleanup others (always single choice)
		if(c !== choice && c.classList.contains(activeClass))
			c.classList.remove(activeClass);
	});

	choice.classList.add(activeClass);
	input.value = value;
	triggerEvent("change", input);
};

/**
 * Determine whether or not the choice is picked
 * @param {Element} choice
 * @returns {boolean}
 */
const choiceIsPicked = choice => choice.classList.contains(activeClass);

/**
 * @param {Element} choice
 * @param {Element|HTMLInputElement} input
 * @param {Iterable<Element>|NodeListOf<Element>} choices
 */
const togglePickChoice = (
	choice,
	input,
	choices
) => {
	if(choiceIsPicked(choice)){
		unpickChoice(
			choice, input, choices
		);
	}
	else{
		pickChoice(
			choice, input, choices
		);
	}
};

/**
 * Pick the given choice and go to the next step
 * @param {Element} choice
 * @param {Element|HTMLInputElement} input
 * @param {Iterable<Element>|NodeListOf<Element>} choices
 * @param {Element|HTMLButtonElement} button
 */
export const pickAndGo = (
	choice, input, choices, button
) => {
	if(!choiceIsPicked(choice)){
		button.setAttribute("aria-disabled", "false");
		pickChoice(
			choice, input, choices
		);
	}
	button.click();
	// triggerEvent("click", button);
};

/**
 * Handle choice management
 * @param {Element|HTMLFormElement} form
 */
export const handleChoices = form => {
	const wrappers = form.querySelectorAll(".m-cards--funding-choices");

	wrappers.forEach(wrapper => {
		const input = wrapper.parentElement.querySelector("input");
		const button = wrapper.parentElement.querySelector(".m-funding-form__button .a-btn");
		const choices = wrapper.querySelectorAll(".m-cards__card");

		if(input === null)
			return; // skip

		choices.forEach(choice => {
			//TODO: Ensure choice.dataset.key === input.name?

			const handleEvent = e => {
				e.preventDefault();
				/*togglePickChoice(
					choice,
					input,
					choices
				);*/

				pickAndGo(
					choice,
					input,
					choices,
					button
				);
			};

			choice.addEventListener("click", handleEvent);
			choice.addEventListener("keydown", e => {
				if(keyPressedIsEnter(e))
					handleEvent(e);
			});
			choice.addEventListener("dblclick", e => {
				// on double click, pick and move on
				e.preventDefault();
				pickAndGo(
					choice, input, choices, button
				);
			});
		});
	});
};
