/**
 * Trigger an event on the given target
 * @param {string} eventName - The event to dispatch
 * @param {Element|null} target - The target of the event
 * @param {boolean} [custom = false] - Whether or not it is a custom event
 * @returns {Event|CustomEvent|null}
 */
export const triggerEvent = (
	eventName,
	target,
	custom = false
) => {
	if(target === null){
		console.error("[utils @ triggerEvent] target is null");
		return null;
	}

	// eslint-disable-next-line no-shadow
	const event = document.createEvent(custom ? "CustomEvent" : "Event");
	event.initEvent(
		eventName, true, true
	);

	target.dispatchEvent(event);
	return event;
};

/**
 * Determine whether or not the current key pressed is enter
 * @param {Event|KeyboardEvent} e
 * @returns {boolean}
 */
export const keyPressedIsEnter = e => e.key === "Enter" || e.which === 13 || e.keyCode === 13;

/**
 * Get the step for a number transition
 * @param {number} x - The number to transition to
 * @returns {number}
 */
export const stepFromNum = x => {
	/* see https://www.dcode.fr/function-equation-finder
		with [x	y] :

		1	1
		50	8
		100	16
		500	48
		1000	96
		2000	200
		10000	886
		100000	16400
		1000000	160400

		as parabola
	 */

	// const y = Math.floor(-2.305 * (10 ** -6) * (x ** 2) + 0.0614 * x + 1.95);
	// const y = Math.floor(-1.6046 * (10 ** -6) * (x ** 2) + 0.06358 * x + 10.27);
	// const y = Math.floor(-4.57497 * (10 ** -9) * (x ** 2) + 0.1651 * x - 133.013);
	const y = Math.floor(-4.0007575 * (10 ** -9) * (x ** 2) + 0.0944145 * x - 6.85655);

	// Do not allow negative steps but keep decimal step and make it at least 1
	return Math.max(1, y);
};

/**
 * Make an array from a generator
 * @param {GeneratorFunction} gen
 * @returns {any[]}
 */
export const arrayFromGenerator = gen => Array.from(gen());

/**
 * Set a function to be executed immediately but not really
 * @param {function} fn - The function to execute
 * @returns {number}
 */
export const setImmediate = typeof window.setImmediate !== "undefined" ? window.setImmediate : fn => {
	return setTimeout(fn, 10);
};

/**
 * Clear the immediate
 * @param {number} id - The immediate's ID
 */
export const clearImmediate = typeof window.clearImmediate !== "undefined" ? window.clearImmediate : clearTimeout;

/**
 * Return the value if it exists, otherwise the default value
 * @template T
 * @template U
 * @param {T|undefined} value - The value to check
 * @param {U} defaultValue - The value to return if value is undefined
 * @returns {T|U}
 */
export const valueOr = (value, defaultValue) => {
	if(typeof value !== "undefined")
		return value;

	return defaultValue;
};
