import { permisValues, situationsValues } from "../enums";

export const inputStateIsOk = input => {
	const isValid = input.checkValidity();

	if(!isValid){
		input.reportValidity();
		return false;
	}

	return input.value !== "";
};

/**
 * @param {HTMLInputElement|Element} input
 * @param {Element} error
 * @param {Function} onSuccess
 */
export const validateInputState = ({
	input,
	error,
	onSuccess,
}) => {
	if(inputStateIsOk(input))
		onSuccess();
	else{
		error.textContent = "Veuillez compléter cette étape";
		error.scrollIntoView({ block: "nearest" });
	}
};

/**
 * Sanitize the form data
 * @param {FormData} formData
 * @returns {FundingFormData}
 */
export const sanitizeData = formData => {
	const raw = {
		permis: formData.get("permis"),
		age: formData.get("age"),
		situation: formData.get("situation"),
	};

	const permis = permisValues.has(raw.permis) ? raw.permis : null;
	const age = Math.max(parseInt(raw.age, 10), 0);
	const situation = situationsValues.has(raw.situation) ? raw.situation : null;

	return {
		permis,
		age,
		situation,
	};
};

