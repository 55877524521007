/**
 * Handler that does nothing and does not halt execution
 * @type {SteppinoHandler}
 */
import { valueOr } from "@js/utils";


const noop = (
	step, data, stepName, steppino
) => true;

/**
 * Handler that does nothing and halts execution
 * @type {SteppinoHandler}
 */
const errorNoop = (
	step, data, stepName, steppino
) => false;

/**
 * Default missing handler group
 * @type {SteppinoHandlerGroup}
 */
const missingHandlerGroup = {
	whenContinue: errorNoop,
	whenCleanup: errorNoop,
};

/**
 * Default handler group
 * @type {SteppinoHandlerGroup}
 */
const defaultHandlerGroup = {
	whenContinue: noop,
	whenCleanup: noop,
};

/**
 * Class that handles logic for a step-by-step form experience
 */
export class Steppino{
	/**
	 * @param {SteppinoSettings} settings
	 */
	constructor({
		form,
		stepSelector,
		steps,
		navigationHook = noop,
	}){
		this.form = form;
		this.stepElements = this.form.querySelectorAll(stepSelector);
		this.index = 0;
		this.steps = steps;
		this.navigationHook = navigationHook;

		/**
		 * The target index for the movement
		 * @type {number|undefined}
		 */
		this.targetIndex = undefined;

		/**
		 * Functions to call once after the next (or current) transition before getting removed
		 * @type {Function[]}
		 * @private
		 */
		this.afterNextTransitionCallbacks = [];

		if(this.stepElements.length !== this.steps.length)
			throw new Error("[Steppino] Mismatch stepElements.length and steps.length");

		// Add default steps hanlders (no-op)
		this.handlers = Object.fromEntries(this.steps.map(step => [
			step,
			{ ...defaultHandlerGroup },
		]));
	}

	/**
	 * Add a callback to execute after transition (next or current)
	 * @param {Function} callback
	 * @return {Steppino}
	 */
	invokeAfterNextTransition(callback){
		this.afterNextTransitionCallbacks.push(callback);
		return this;
	}

	/**
	 * Get the current progress
	 * @returns {number} Between 0 and 1
	 */
	getProgress(){
		return this.index / this.stepElements.length;
	}

	/**
	 * Get the step element by its step name
	 * @param {string} stepName
	 * @returns {Element|null}
	 */
	getStepElementByName(stepName){
		const index = this.steps.indexOf(stepName);

		return index >= 0
			? this.stepElements.item(index)
			: null;
	}

	/**
	 * Get the step's input by its step name
	 * @param {string} stepName
	 * @returns {HTMLInputElement|null}
	 */
	getStepInput(stepName){
		const step = this.getStepElementByName(stepName);
		if(!step)
			return null;

		return step.querySelector("input");
	}

	/**
	 * Get the step's action button by its step name
	 * @param {string} stepName
	 * @returns {null|Element}
	 */
	getStepActionButton(stepName){
		const step = this.getStepElementByName(stepName);
		if(!step)
			return null;

		return step.querySelector(".a-btn");
	}

	/**
	 * Get the current step name
	 * @returns {string}
	 */
	getCurrentStepName(){
		return this.steps[this.index];
	}

	/**
	 * Get the current step handler group
	 * @returns {SteppinoHandlerGroup}
	 */
	getCurrentHandlerGroup(){
		const step = this.getCurrentStepName();
		return this.handlers[step] || { ...missingHandlerGroup };
	}

	/**
	 * Get the current step element
	 * @returns {Element}
	 */
	getCurrentStepElement(){
		return this.stepElements.item(this.index);
	}

	/**
	 * Set the handler group for the given step
	 * @param {string} step - The step's name
	 * @param {SteppinoHandlerGroup} handlerGroup - The step's handler group
	 * @returns {Steppino}
	 */
	setStepHandlerGroup(step, handlerGroup){
		this.handlers[step] = handlerGroup;
		return this;
	}

	/**
	 * Set the step's cleanup handler
	 * @param {string} step - The step's name
	 * @param {SteppinoHandler} whenCleanup - The step's handler
	 * @returns {Steppino}
	 */
	setStepWhenCleanup(step, whenCleanup){
		if(typeof this.handlers[step] !== "undefined")
			this.handlers[step].whenCleanup = whenCleanup;

		return this;
	}

	/**
	 * Set the step's continuation handler
	 * @param {string} step - The step's name
	 * @param {SteppinoHandler} whenContinue - The step's handler
	 * @returns {Steppino}
	 */
	setStepWhenContinue(step, whenContinue){
		if(typeof this.handlers[step] !== "undefined")
			this.handlers[step].whenContinue = whenContinue;

		return this;
	}

	/**
	 * Set the global "did navigate" handler
	 * @param {SteppinoNavigationHook} navigationHook - The handler to call each time we navigate to a step
	 * @returns {Steppino}
	 */
	setNavigationHook(navigationHook){
		this.navigationHook = navigationHook;
		return this;
	}

	/**
	 * Whether or not it can move forward
	 * @returns {boolean}
	 */
	canMoveForward(){
		return this.index < this.steps.length - 1;
	}

	/**
	 * Whether or not it can move backward
	 * @returns {boolean}
	 */
	canMoveBackward(){
		return this.index > 0;
	}

	/**
	 * Move forward by one step
	 * @param {boolean} forceMove - Whether or not it should bypass checks
	 * @returns {boolean} Whether or not it moved
	 */
	moveForward(forceMove = false){
		if(!this.canMoveForward())
			return false;

		const didMove = this.__withTarget(this.index + 1, () => {
			const didMove = forceMove || this.__doContinue();

			if(didMove){
				this.index += 1;
				this.__triggerNavigationHook();
			}

			return didMove;
		});
		return didMove;
	}

	/**
	 * Move back by one step
	 * @param {boolean} [forceMove = false] - Whether or not it should bypass checks
	 * @returns {boolean} Whether or not it moved
	 */
	moveBackward(forceMove = false){
		if(!this.canMoveBackward())
			return false;


		const didMove = this.__withTarget(this.index - 1, () => {
			const didMove = forceMove || this.__doCleanup();

			if(didMove){
				this.index -= 1;
				this.__triggerNavigationHook();
			}

			return didMove;
		});
		return didMove;
	}

	/**
	 * Move to the given index
	 * @param {number} index
	 * @returns {boolean} Whether or not it moved
	 */
	moveTo(index){
		const cannotMove = [
			index < 0, // negative index
			index >= this.steps.length, // overrun index
			this.index === index, // not moving
			index < this.index && !this.canMoveBackward(), // backward but cannot move
			// eslint-disable-next-line keyword-spacing
			index > this.index + 1,
		].some(cond => cond);

		if(cannotMove)
			return false;
		else if(index === this.index + 1)
			return this.moveForward();
		else{ // obligate to be < this.index
			const didMove = this.__withTarget(index, () => {
				let didMove = false;

				do
					didMove = this.moveBackward();
				while(this.index !== index && didMove);

				return didMove;
			});

			if(didMove)
				this.__triggerNavigationHook();

			return didMove;
		}
	}

	/*forceMoveTo(index){
		let didMove = this.moveTo(index);

		if(didMove || index === this.index)
			return true;

		if(index < 0 || index >= this.steps.length)
			return false;

		const goingForward = this.index < index;
		const method = goingForward ? "moveForward" : "moveBackward";
		const onChangeMethod = goingForward ? "__doContinue" : "__doCleanup";

		do{
			if(!goingForward)
				this[onChangeMethod]();

			didMove = this[method](true /!*bypass checks*!/);
		}while(this.index !== index && didMove);

		if(didMove)
			this.__triggerNavigationHook();
	}*/

	/**
	 * Get the form's data
	 * @returns {FormData}
	 */
	getData(){
		return new FormData(this.form);
	}





	__changeTarget(targetIndex){
		const neededChange = typeof this.targetIndex === "undefined";
		this.targetIndex = valueOr(this.targetIndex, targetIndex);
		return neededChange;
	}

	__cleanTarget(){
		this.targetIndex = undefined;
	}

	__withTarget(targetIndex, fn){
		const shouldCleanTarget = this.__changeTarget(targetIndex);
		const ret = fn(shouldCleanTarget);

		if(shouldCleanTarget){
			this.__cleanTarget();

			if(ret)
				this.__invokeOnceCallbacks();
		}

		return ret;
	}

	__doContinue(){
		const step = this.getCurrentStepElement();
		const stepName = this.getCurrentStepName();
		const data = this.getData();
		const { whenContinue } = this.getCurrentHandlerGroup();

		return whenContinue(
			step,
			data,
			stepName
		);
	}

	__doCleanup(){
		const step = this.getCurrentStepElement();
		const stepName = this.getCurrentStepName();
		const data = this.getData();
		const { whenCleanup } = this.getCurrentHandlerGroup();

		return whenCleanup(
			step,
			data,
			stepName
		);
	}

	__triggerNavigationHook(){
		this.navigationHook(
			this.getCurrentStepElement(),
			this.getData(),
			this.getCurrentStepName(),
			this
		);
	}

	__invokeOnceCallbacks(){
		const callbacks = [...this.afterNextTransitionCallbacks]; // get the callbacks
		this.afterNextTransitionCallbacks = []; // reset before invoking to avoid "infinite" unwanted calls
		callbacks.forEach(callback => callback(this));
	}
}
