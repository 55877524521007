export const Breakpoints = {
	__default: 0,
	small: 568,
	medium: 768,
	large: 1024,
	xlarge: 1280,
	xxlarge: 1440,
	xxxlarge: 1680,
};

export const belowBreakpoint = bp => window.innerWidth < bp;

export const aboveBreakpoint = bp => !belowBreakpoint(bp);

export const currentBreakpoint = () => {
	/*
		 Current breakpoint is the last for which the
		 window's inner width is above
	 */
	return Object.values(Breakpoints)
		.reverse()
		.find(aboveBreakpoint);
};

export const currentBreakpointIs = bp => {
	const current = currentBreakpoint();
	return bp === current;
};

/**
 * @callback BreakpointEventListener
 * @param {number} current - The current breakpoint
 * @param {number} prev - The previous breakpoint
 * @returns {any}
 */

/**
 * Breakpoint events helper
 */
export const BreakpointEvents = {
	__: {
		/**
		 * @type {number}
		 */
		lastBreakpoint: currentBreakpoint(),
		listeners: {
			/**
			 * @type {BreakpointEventListener[]}
			 */
			change: [],

			/**
			 * @type {BreakpointEventListener[]}
			 */
			bigger: [],

			/**
			 * @type {BreakpointEventListener[]}
			 */
			smaller: [],
		},
	},
	on: {
		/**
		 * Get notified on breakpoint change
		 * @param {BreakpointEventListener} listener
		 */
		change(listener){
			BreakpointEvents.__.listeners.change.push(listener);
		},
		/**
		 * Get notified on breakpoint change (only when it gets bigger)
		 * @param {BreakpointEventListener} listener
		 */
		bigger(listener){
			BreakpointEvents.__.listeners.bigger.push(listener);
		},
		/**
		 * Get notified on breakpoint change (only when it gets smaller)
		 * @param {BreakpointEventListener} listener
		 */
		smaller(listener){
			BreakpointEvents.__.listeners.smaller.push(listener);
		},
	},
};

/**
 * Handle breakpoint events on resize
 */
export const onResize = () => {
	const bp = currentBreakpoint();
	const last = BreakpointEvents.__.lastBreakpoint;

	if(bp !== last){ // On breakpoint change
		BreakpointEvents.__
			.listeners
			.change
			.forEach(f => f(bp, last));

		if(bp > last){ // When the viewport got bigger
			BreakpointEvents.__
				.listeners
				.bigger
				.forEach(f => f(bp, last));
		}
		else{ // When the viewport got smaller
			BreakpointEvents.__
				.listeners
				.smaller
				.forEach(f => f(bp, last));
		}
	}

	BreakpointEvents.__.lastBreakpoint = bp;
};

window.addEventListener("resize", onResize);
