import { ls } from "@js/modules/localStorage";
import { nextWeek } from "@js/modules/date";
import { LOCAL_STORAGE_KEY } from "./constants";
import { sanitizeData } from "./validation";
import { startFromQueryString } from "./queryString";

export const clearStateStorage = () => ls.remove(LOCAL_STORAGE_KEY);

export const clearHistory = () => {
	history.replaceState(
		null,
		null,
		location.pathname
	);
};

/**
 * Reset the steppino state
 * @param {Steppino} steppino
 * @param {import("swiper").Swiper} swiper
 */
export const resetState = (steppino, swiper) => {
	clearStateStorage();
	const index = 0;
	const didMove = steppino.moveTo(index);

	if(didMove)
		swiper.slideTo(index);
		// clearHistory();
	else
		console.error("[modules/domain/funding/form/steps/persistence @ resetState] Did not move");

	//TODO: Handle error
};

/**
 * Save the steppino state
 * @param {Steppino} steppino
 */
export const saveState = steppino => {
	const data = sanitizeData(steppino.getData());

	ls.set(
		LOCAL_STORAGE_KEY,
		data,
		{ expireAt: nextWeek() }
	);
};

/**
 * Restore the saved steppino state
 * @param {SteppinoSwiperController} controller
 */
export const restoreState = controller => {
	startFromQueryString(controller);
};
