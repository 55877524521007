import { isFundingPage } from "@js/modules/domain/funding/shared";
import { handleSteps } from "@js/modules/domain/funding/form/steps";
import { handleChoices } from "@js/modules/domain/funding/form/choices";

const isFundingForm = () => {
	// Might need more complicated logic
	return isFundingPage();
};



export const handleFundingForm = () => {
	if(!isFundingForm())
		return;

	const form = document.querySelector(".m-funding-form");

	if(form === null){
		console.error("[domain/funding/form] No form");
		return;
	}


	// Prevent accidental form submission
	form.addEventListener("submit", e => {
		e.preventDefault();
	});

	handleChoices(form);
	handleSteps(form);
};
