const animationDelay = 2000;
const successClass = "-success";
const failureClass = "-failure";
const targetSelector = "[data-copy-target]";

/**
 * Copy to clipboard using the modern Clipboard API
 * @param {string} content
 * @returns {Promise<boolean>}
 */
const modernCopy = async content => {
	if(
		"clipboard" in navigator &&
		typeof navigator.clipboard.writeText === "function"
	){
		try{
			await navigator.clipboard.writeText(content);
			return true;
		}
		catch(e){
			console.error(
				"Failed to copy into clipboard using modern API",
				content,
				e
			);
		}
	}

	return false;
};

/**
 * Copy to clipboard using the legacy API
 * @param {string} content
 * @returns {Promise<boolean>}
 */
const legacyCopy = async content => {
	const focusedElement = document.activeElement;

	const textarea = document.createElement("textarea");
	textarea.value = content;

	// Add it to DOM but do not "show" it and do not trigger scroll
	textarea.style.top = "0";
	textarea.style.left = "0";
	textarea.style.position = "fixed";
	textarea.style.opacity = "0";

	document.body.appendChild(textarea);
	textarea.focus();
	textarea.select();

	let didCopy = false;

	try{
		didCopy = document.execCommand("copy");
	}
	catch(e){
		console.error(
			"Failed to copy into clipboard using legacy API",
			content,
			e
		);
	}
	finally{
		focusedElement?.focus();
		textarea.remove();
	}

	return didCopy;
};

/**
 * Copy a string to the clipboard
 * @param {string} content
 * @returns {Promise<boolean>}
 */
export const copyToClipboard = async content => {
	const copiedUsingModernApi = await modernCopy(content);
	return copiedUsingModernApi || legacyCopy(content);
};

/**
 * @param {HTMLButtonElement|HTMLAnchorElement|HTMLSpanElement} el - The action element that triggered the copy
 * @param {HTMLElement} target - The copy target
 */
export const signalCopySuccess = (el, target) => {
	const { textContent } = target;
	target.textContent = "Copié !";
	el.classList.add(successClass);
	target.classList.add(successClass);
	return setTimeout(() => {
		el.classList.remove(successClass);
		target.classList.remove(successClass);
		target.textContent = textContent;
	}, animationDelay);
};

/**
 * @param {HTMLButtonElement|HTMLAnchorElement|HTMLSpanElement} el - The action element that triggered the copy
 * @param {HTMLElement} target - The copy target
 */
export const signalCopyFailure = (el, target) => {
	const { textContent } = target;
	target.textContent = "La copie a échoué";
	el.classList.add(failureClass);
	target.classList.add(failureClass);
	return setTimeout(() => {
		el.classList.remove(failureClass);
		target.classList.remove(failureClass);
		target.textContent = textContent;
	}, animationDelay);
};

/**
 * @param {HTMLButtonElement|HTMLAnchorElement|HTMLSpanElement} el
 * @returns {HTMLElement|null|undefined}
 */
const extractCopyTarget = el => {
	const prev = el.previousElementSibling;

	if(prev?.matches(targetSelector))
		return prev;


	return prev?.querySelector(targetSelector);
};

/**
 * @param {HTMLButtonElement|HTMLAnchorElement|HTMLSpanElement} el - The element to bind copy-to-clipboard actions to
 */
export const makeClipboard = el => {
	const target = extractCopyTarget(el);
	if(!target){
		console.warn("Copy to clipboard without a target, remember to add the `data-copy-target` attribute to the target",
			el);
		return;
	}

	if(!el.hasAttribute("title"))
		el.setAttribute("title", "Copier dans le presse-papiers");


	el.addEventListener("click", e => {
		if(e.target !== el)
			return;


		e.preventDefault();
		e.stopPropagation();
		el.blur();

		(async() => {
			const content = target.textContent.trim();
			const didCopy = await copyToClipboard(content);

			if(didCopy)
				signalCopySuccess(el, target);

			else
				signalCopyFailure(el, target);
		})();
	});
};
