import { qsHas } from "@js/modules/queryString";
import { clearStateStorage } from "@js/modules/domain/funding/form/steps/persistence";
import { qsGet } from "../../../../queryString";
import { permisValues } from "../enums";
import { QS_PERMIS_KEY, QS_RESET_KEY } from "./constants";
import { force } from "./manualIteration";

/**
 * Start the steppino from the query string state
 * @param {SteppinoSwiperController} controller
 * @returns {boolean} Whether or not it handled the start
 */
export const startFromQueryString = controller => {
	if(qsHas(QS_RESET_KEY)){
		clearStateStorage();
		return true;
	}

	if(!qsHas(QS_PERMIS_KEY))
		return false;

	const permis = qsGet(QS_PERMIS_KEY).trim();
	if(!permisValues.has(permis))
		return false;


	return force.permis(controller, permis);
};
