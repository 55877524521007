const activeClass = "-active";

/**
 * @param {number} ratio
 * @returns {string}
 */
const rootMarginForRatio = ratio => {
	const percentage = `${ratio * 100}%`;
	return `-${percentage} 0px -${percentage} 0px`;
};

/**
 * @param {string} uri
 * @returns {string}
 */
const idFromAnchorUri = uri => {
	const idx = uri.lastIndexOf("#");
	return uri.substring(idx + 1);
};

// cf. https://grafikart.fr/tutoriels/scrollspy-js-page-491
export class ScrollSpy{
	/**
	 * @param {ScrollspySettings} [settings]
	 */
	constructor({
		anchors, sectionRatio = 0.4, autoInit = true,
	} = {}){
		this.anchors = anchors;
		this.sectionRatio = sectionRatio;

		this.ids = anchors.map(anchor => idFromAnchorUri(anchor.href));
		this.sections = this.ids.map(id => document.getElementById(id));
		this.mapping = new WeakMap(this.anchors.map((anchor, index) => {
			return [this.sections[index], anchor];
		}));

		this.intersectionObserver = new IntersectionObserver(entries => {
			entries.forEach(entry => {
				if(entry.isIntersecting)
					this.activateAnchorFor(entry.target);
			});
		},
		{ rootMargin: rootMarginForRatio(this.sectionRatio) });

		if(autoInit)
			this.init();
	}

	init(){
		this.deactivateAllAnchors();

		this.sections.forEach(section => {
			this.intersectionObserver.observe(section);
		});
	}

	destroy(){
		this.deactivateAllAnchors();

		this.sections.forEach(section => {
			this.intersectionObserver.unobserve(section);
		});
	}

	/**
	 * @param {HTMLElement} section
	 */
	activateAnchorFor(section){
		const anchor = this.mapping.get(section);

		if(anchor.classList.contains(activeClass))
			return;


		this.deactivateAllAnchors();
		anchor.classList.add(activeClass);
	}

	deactivateAllAnchors(){
		this.anchors.forEach(anchor => {
			anchor.classList.remove(activeClass);
		});
	}
}
