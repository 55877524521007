export const url = new URL(location.href);

export const params = new URLSearchParams(url.searchParams);

export const qsHas = key => params.has(key);

export const qsGet = key => params.get(key);

// eslint-disable-next-line no-confusing-arrow
export const qsGetOr = (key, defaultValue) => qsHas(key) ? qsGet(key) : defaultValue;

export const qsIs = (key, value) => qsGet(key) === value;

export const getHash = () => location.hash.substr(1);
