/**
 * @typedef {object} Elements
 * @property {Element} first
 * @property {Element} last
 * @property {Iterable<Element>|NodeListOf<Element>} all
 */


const KEYCODE_TAB = 9;

class FocusinoImpl{
	/**
	 * @param {Focusino} self
	 */
	// eslint-disable-next-line no-shadow
	constructor(self){
		this.self = self;

		this.eventHandler = this.eventHandler.bind(this);
	}

	/**
	 * Get the autofocus element inside the container
	 * @return {Element|null}
	 */
	getAutofocusElement(){
		return this.self.container.querySelector("[data-autofocus]");
	}

	/**
	 * Retrieve the currently focused element
	 * @return {Element}
	 */
	getFocusedElement(){
		return document.activeElement;
	}

	/**
	 * Get the focusable elements
	 * @return {Elements}
	 */
	getFocusableElements(){
		const elements = this.self.container.querySelectorAll(`
			a[href]:not([disabled]),
			button:not([disabled]),
			textarea:not([disabled]),
			input:not([disabled]):not([type="hidden"]),
			select:not([disabled])
		`);

		return {
			first: elements[0],
			last: elements[elements.length - 1],
			all: elements,
		};
	}



	eventHandler(e){
		const { self: { isTrapped, elements } } = this;
		if(!isTrapped)
			return;

		if(e.key === "Tab" || e.keyCode === KEYCODE_TAB){
			if(e.shiftKey){ // shift+tab
				if(document.activeElement === elements.first){
					e.preventDefault();
					elements.last.focus();
				}
			}
			else{ // tab
				// eslint-disable-next-line no-lonely-if
				if(document.activeElement === elements.last){
					e.preventDefault();
					elements.first.focus();
				}
			}
		}
	}

	bindEventHandler(){
		this.self
			.container
			.addEventListener("keydown", this.eventHandler);
	}
}


/**
 * Trap focus inside an element
 */
export class Focusino{
	/**
	 * @param {Element} container
	 */
	constructor(container){
		/**
		 * @type {Element}
		 * @private
		 * @readonly
		 */
		this.container = container;


		/**
		 * @type {FocusinoImpl}
		 * @private
		 * @readonly
		 */
		this.__ = new FocusinoImpl(this);

		/**
		 * Keep track of the last focused element outside the container
		 * This allows to re-focus when the modal closes
		 * @type {Element}
		 * @private
		 */
		this.lastFocused = this.__.getFocusedElement();

		/**
		 * @type {Elements}
		 * @private
		 */
		this.elements = this.__.getFocusableElements();

		/**
		 * The element that can be autofocused on entering the focus trap in the container
		 * @type {Element|null}
		 * @private
		 */
		this.autofocusEl = this.__.getAutofocusElement();

		/**
		 * @type {boolean}
		 * @private
		 */
		this.isTrapped = false;


		this.__.bindEventHandler();
	}

	/**
	 * Trap the focus inside the container
	 */
	trap(){
		this.lastFocused = this.__.getFocusedElement();
		this.elements = this.__.getFocusableElements();
		this.isTrapped = true;
		this.elements.first.focus();
	}

	/**
	 * Stop trapping focus inside
	 */
	untrap(){
		this.isTrapped = false;
	}

	/**
	 * Focus the auto-focusable element inside the container
	 */
	focusInside(){
		if(this.autofocusEl)
			this.autofocusEl.focus();
	}

	/**
	 * Focus the last element that was focused outside the container
	 */
	focusBackOutside(){
		this.lastFocused.focus();
	}

	/**
	 * Make the focus enter the container
	 */
	enter(){
		this.trap();
		this.focusInside();
	}

	/**
	 * Make the focus leave the container
	 */
	leave(){
		this.untrap();
		this.focusBackOutside();
	}
}
