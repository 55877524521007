import { lockBody, unlockBody } from "@js/modules/body";
import { Focusino } from "@js/libs/Focusino";

/**
 * @callback ModalinoEventHandler
 * @param {Modalino} modalino - The modalino instance
 */


/**
 * Modal implementation details
 */
class ModalinoImpl{
	/**
	 * @param {Modalino} self
	 */
	// eslint-disable-next-line no-shadow
	constructor(self){
		this.self = self;

		/**
		 * @type {ModalinoEventHandler}
		 */
		this.onShow = _ => {};

		/**
		 * @type {ModalinoEventHandler}
		 */
		this.onClose = _ => {};
	}

	setupListeners(){
		// eslint-disable-next-line no-shadow
		const { self } = this;

		// For items that can close the modal
		self.el.querySelectorAll("[data-modal-close]")
			.forEach(el => el.addEventListener("click", e => {
				if(e.target === el){
					e.preventDefault();
					self.close();
				}
			}));

		// For items that can open the modal
		document.querySelectorAll(`[data-modal-trigger="${self.id}"], [href="#${self.id}"]`)
			.forEach(el => el.addEventListener("click", e => {
				e.preventDefault();
				self.show();
			}));
	}

	addOpenClass(){
		// eslint-disable-next-line no-shadow
		const { self } = this;

		if(self.openClass)
			self.el.classList.add(self.openClass);
	}

	removeOpenClass(){
		// eslint-disable-next-line no-shadow
		const { self } = this;

		if(self.openClass)
			self.el.classList.remove(self.openClass);
	}

	accessibilityOnShow(){
		// eslint-disable-next-line no-shadow
		const { self } = this;
		const { el } = self;
		el.removeAttribute("hidden");
		el.setAttribute("aria-hidden", "false");
	}

	accessibilityOnClose(){
		// eslint-disable-next-line no-shadow
		const { self } = this;
		const { el } = self;
		el.setAttribute("hidden", "hidden");
		el.setAttribute("aria-hidden", "false");
	}
}


/**
 * Modal handler
 */
export class Modalino{
	/**
	 * @param {Element} el - The modal element to handle
	 */
	constructor(el){
		if(el.id === "")
			throw new Error("Modal must have an id");

		/**
		 * Modal element
		 * @type {Element}
		 * @readonly
		 */
		this.el = el;

		/**
		 * ID of the modal element
		 * @type {string}
		 * @readonly
		 */
		this.id = this.el.id;

		/**
		 * Class to add/remove on open/close
		 * @type {string}
		 * @readonly
		 */
		this.openClass = "m-modal--open";

		/**
		 * Focus trapping helper
		 * @type {Focusino}
		 * @readonly
		 */
		this.focusino = new Focusino(this.el);

		/**
		 * @type {ModalinoImpl}
		 * @private
		 * @readonly
		 */
		this.__ = new ModalinoImpl(this);

		this.__.setupListeners();
	}

	/**
	 * Change the callback to be called when the modal is shown
	 * @param {ModalinoEventHandler} callback
	 * @returns {Modalino}
	 */
	onShow(callback){
		this.__.onShow = callback;
		return this;
	}

	/**
	 * Change the callback to be called when the modal is closed
	 * @param {ModalinoEventHandler} callback
	 * @returns {Modalino}
	 */
	onClose(callback){
		this.__.onClose = callback;
		return this;
	}

	/**
	 * Show/open the modal
	 */
	show(){
		this.__.onShow(this);
		lockBody();
		this.__.addOpenClass();
		this.__.accessibilityOnShow();
		this.focusino.enter(); // do after accessibility to avoid ARIA conflicts in semantic (e.g. focus outside of allowed elements)
	}

	/**
	 * Close/hide the modal
	 */
	close(){
		unlockBody();
		this.__.removeOpenClass();
		this.__.accessibilityOnClose();
		this.focusino.leave(); // do after accessibility to avoid ARIA conflicts in semantic (e.g. focus outside of allowed elements)
		this.__.onClose(this);
	}
}


/*
Example markup :

<div class="m-modal" id="my_id" hidden aria-hidden="true">
	<div class="m-modal__overlay" tabindex="-1" data-modal-close="my_id"></div>

	<div class="m-modal__wrapper">
		<div class="m-modal__container" role="dialog" aria-modal="true" aria-labelledby="my_id-title">
			<button class="m-modal__close" aria-label="Fermer la fenêtre" data-modal-close>
				<svg class="m-modal__close-icon" viewBox="0 0 18 17" data-modal-close>
					{# viewBox to keep the correct size #}
					<use xlink:href="#close-cross"></use>
				</svg>
			</button>

			<h2 class="m-modal__title a-heading a-heading--2" id="my_id-title">
				My title
			</h2>

			<div class="m-modal__content" id="my_id-content">
				Modal Content
			</div>
		</div>
	</div>
</div>
 */
