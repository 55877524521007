import {
	aboveBreakpoint, BreakpointEvents,
	Breakpoints,
	currentBreakpoint,
} from "@js/modules/breakpoints";
import { lockBody, unlockBody } from "@js/modules/body";

const closeBurger = ({
	burger,
	menu,
	burgerActiveClass,
	menuActiveClass,
	openIcon,
	closeIcon,
}) => {
	burger.classList.remove(burgerActiveClass);
	burger.setAttribute("aria-expanded", "false");
	menu.classList.remove(menuActiveClass);

	openIcon.classList.add("hidden");
	closeIcon.classList.remove("hidden");
	unlockBody();
};

const openBurger = ({
	burger,
	menu,
	burgerActiveClass,
	menuActiveClass,
	openIcon,
	closeIcon,
}) => {
	burger.classList.add(burgerActiveClass);
	burger.setAttribute("aria-expanded", "true");
	menu.classList.add(menuActiveClass);

	closeIcon.classList.add("hidden");
	openIcon.classList.remove("hidden");
	lockBody();
};

/**
 * Accessibility hide the burger menu
 * @param burger
 */
const hideBurger = ({ burger }) => {
	if(burger.hasAttribute("hidden"))
		return; // Avoid redundant updates

	burger.setAttribute("hidden", "hidden");
	burger.setAttribute("aria-hidden", "true");
	burger.setAttribute("tabindex", "-1");
};

/**
 * Accessibility show the burger menu
 * @param burger
 */
const showBurger = ({ burger }) => {
	if(!burger.hasAttribute("hidden"))
		return; // Avoid redundant updates

	burger.removeAttribute("hidden");
	burger.setAttribute("aria-hidden", "false");
	burger.setAttribute("tabindex", "0");
};

export const handleHamburgerMenu = () => {
	const burger = document.querySelector(".hamburger");
	const menu = document.querySelector(".o-header__right");
	const openIcon = burger.querySelector("[data-opened]");
	const closeIcon = burger.querySelector("[data-closed]");
	const burgerActiveClass = "is-active";
	const menuActiveClass = "o-header__right--opened";

	const payload = {
		burger,
		menu,
		burgerActiveClass,
		menuActiveClass,
		openIcon,
		closeIcon,
	};

	burger.addEventListener("click", e => { // change status on click
		if(aboveBreakpoint(Breakpoints.large)){
			closeBurger(payload);
			hideBurger(payload);
			return;
		}
		else
			showBurger(payload);

		const isOpen = burger.classList.contains(burgerActiveClass);

		if(isOpen)
			closeBurger(payload);
		else
			openBurger(payload);
	});


	BreakpointEvents.on.bigger((currentBP, prevBP) => {
		// Auto close on resize (when above breakpoint)
		if(aboveBreakpoint(Breakpoints.large)){
			closeBurger(payload);
			hideBurger(payload);
		}
	});
};


export const setupAllHamburgerMenu = () => {
	handleHamburgerMenu();
};
