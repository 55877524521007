/**
 * List of displayable situations
 * @param {FundingFormData} params
 * @returns {string[]}
 */
import {
	Permis,
	Situation,
	situationsValues,
} from "@js/modules/domain/funding/form/enums";


const situationsForAuto = age => {
	//TODO: Simplify this mess?
	const situations = new Set();

	if(age > 25){
		[
			Situation.APPRENTISSAGE,
			Situation.SALARIE,
			Situation.DEMANDEUR_EMPLOI,
			Situation.DEMANDEUR_EMPLOI_RSA,
			Situation.INDEPENDANT,
			Situation.RESERVISTE,
			Situation.HANDICAP,
		].forEach(x => situations.add(x));
	}
	else if(age >= 15 && age <= 25){
		[
			Situation.APPRENTISSAGE,
			Situation.ETUDIANT,
			Situation.HANDICAP,
		].forEach(x => situations.add(x));

		if(age >= 16){
			[
				Situation.SALARIE,
				Situation.DEMANDEUR_EMPLOI,
				Situation.DEMANDEUR_EMPLOI_RSA,
				Situation.INDEPENDANT,
			].forEach(x => situations.add(x));
		}

		if(age >= 17)
			situations.add(Situation.RESERVISTE);
	}



	return Array.from(situations.values());
};

const situationsForMoto = age => {
	/*const situations = new Set();

	if(age >= 16 && age <= 25){
		[
			Situation.APPRENTISSAGE, // ajout
			// Situation.RESERVISTE,
			Situation.ETUDIANT,
			Situation.SALARIE,
			Situation.DEMANDEUR_EMPLOI,
			Situation.DEMANDEUR_EMPLOI_RSA,
			Situation.INDEPENDANT,
			Situation.HANDICAP,
		].forEach(x => situations.add(x));

		if(age >= 17)
			situations.add(Situation.RESERVISTE);
	}

	return Array.from(situations.values());*/
	return Array.from(situationsValues.values());
};

/**
 * @param {object} payload
 * @param {string} payload.permis
 * @param {number} payload.age
 * @returns {string[]}
 */
export const displayableSituations = ({ permis, age }) => {
	switch(permis){
	case Permis.SCOOTER:
		return age >= 14 && age <= 29 ? [ Situation.APPRENTISSAGE ] : [];

	case Permis.AUTO:
		return situationsForAuto(age);

	case Permis.GROUPE_LOURD:
		return age < 18 ? [] : [
			Situation.SALARIE,
			Situation.DEMANDEUR_EMPLOI,
			Situation.DEMANDEUR_EMPLOI_RSA,
			Situation.INDEPENDANT,
			Situation.RESERVISTE,
			Situation.HANDICAP,
		];

	case Permis.MOTO:
		return situationsForMoto(age);

	case Permis.BATEAU:
		return age >= 16 ? [ Situation.FORMATION_PRO ] : [];

	default:
		return [];
	}
};
