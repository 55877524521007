/**
 * Hide the back-to-top button
 * @param {Element|HTMLButtonElement|HTMLAnchorElement} backToTop - The back-to-top button
 * @param {string} hiddenClass - The hidden class for the button (without the leading dot)
 */
const showBackToTop = ({ backToTop, hiddenClass }) => {
	backToTop.classList.remove(hiddenClass);
	backToTop.setAttribute("aria-disabled", "false");
};

/**
 * Show the back-to-top button
 * @param {Element|HTMLButtonElement|HTMLAnchorElement} backToTop - The back-to-top button
 * @param {string} hiddenClass - The hidden class for the button (without the leading dot)
 */
const hideBackToTop = ({ backToTop, hiddenClass }) => {
	backToTop.classList.add(hiddenClass);
	backToTop.setAttribute("aria-disabled", "true");
};

/**
 * Setup the scroll to top button
 * @param {number} pixelsFromTop - The amount of pixels from the top to enable smooth scrolling
 */
export const setupScrollToTop = pixelsFromTop => {
	const hiddenClass = "m-back-to-top--hidden";
	const backToTop = document.querySelector(".m-back-to-top");
	const payload = {
		hiddenClass,
		backToTop,
	};

	backToTop.addEventListener("click", () => {
		location.hash = ""; // clear the hash before moving
	});

	// Header on scroll
	const longScroll = window.innerHeight / 2;
	const onScroll = () => {
		if(window.scrollY > pixelsFromTop && !document.body.classList.contains("o-body--scrolled")){
			document.body.classList.add("o-body--scrolled");
			showBackToTop(payload);
		}

		else if(window.scrollY <= pixelsFromTop){
			document.body.classList.remove("o-body--scrolled");
			hideBackToTop(payload);
		}

		if(window.scrollY > longScroll && !document.body.classList.contains("o-body--long-scrolled"))
			document.body.classList.add("o-body--long-scrolled");

		else if(window.scrollY <= longScroll)
			document.body.classList.remove("o-body--long-scrolled");
	};

	window.addEventListener("scroll", onScroll);
	onScroll();
};


export const setupAllScroll = pixelsFromTop => {
	setupScrollToTop(pixelsFromTop);
	// setupSmoothScroll(pixelsFromTop);
};
