import { time } from "@js/modules/time";
import { triggerEvent } from "@js/utils";
import { clearStateStorage } from "../persistence";
import { withErrorPanel, withoutErrorPanel } from "../errorPanel";
import { sanitizeData } from "../validation";
import { displayableSituations } from "../../constraints/situations";
import { Situation, situationsValues } from "../../enums";

/**
 *
 * @param {SteppinoSwiperController} controller
 * @param {object} elements
 * @param {Element} elements.situationsEl
 * @param {Element} elements.resultsEl
 */
export const handleSituations = (controller, { situationsEl, resultsEl }) => {
	const { steppino } = controller;
	const stepName = "situation";
	const hiddenClass = "m-cards__card--hidden";

	steppino.setStepWhenContinue("age", (step, data) => {
		const saneData = sanitizeData(data);
		const situationNames = displayableSituations(saneData).filter(name => situationsValues.has(name));

		if(situationNames.length === 0){
			withErrorPanel({
				step: resultsEl,
				// step: steppino.getStepElementByName(stepName),
				errorLocation: "handleSituations",
			});

			controller.thenMoveForward(() => { // Skip to result step
				const input = steppino.getStepInput(stepName); //TODO: Handle failure
				// const btn = steppino.getStepActionButton(stepName); //TODO: Handle failure
				input.value = Situation.___ERROR_STATE;
				triggerEvent("change", input);
				// btn.click();
			});

			/*setTimeout(() => { // Skip to result step
				const input = steppino.getStepInput(stepName); //TODO: Handle failure
				const btn = steppino.getStepActionButton(stepName); //TODO: Handle failure
				input.value = Situation.___ERROR_STATE;
				triggerEvent("change", input);
				btn.click();
			}, time.milliseconds(500));*/
		}
		else{
			situationNames.forEach(name => {
				const situation = situationsEl.querySelector(`[data-value="${name}"]`);

				if(situation)
					situation.classList.remove(hiddenClass);
			});
		}

		return true;
	});

	steppino.setStepWhenCleanup(stepName, step => {
		clearStateStorage();

		// Hide everything back
		situationsEl.querySelectorAll(`.m-funding-form__choice:not(.${hiddenClass})`)
			.forEach(el => el.classList.add(hiddenClass));

		// Deselect
		situationsEl.querySelectorAll(".m-cards__card--active")
			.forEach(el => el.classList.remove("m-cards__card--active"));

		// Clean up input state
		const input = situationsEl.querySelector("input");
		if(input)
			input.value = "";

		const btn = situationsEl.querySelector(".a-btn");
		if(btn)
			btn.setAttribute("aria-disabled", "true");

		withoutErrorPanel({
			step: resultsEl,
			errorLocation: "handleSituations",
		});

		return true;
	});
};
