/**
 * @callback ElementDecorator
 * @param {Element} el - The element to decorate
 * @returns {Element} The structure to decorate it with (can then append el to it)
 */

export class Passwordino{
	/**
	 * @param {Element|HTMLInputElement} el - The password input to wrap
	 */
	constructor(el){
		this.el = el;
		this.isShowing = true;
		this.hide();
	}

	/**
	 * Decorate this password input
	 * @param {ElementDecorator} decorator - The decorator function
	 * @returns {Element} The decoration
	 */
	decorate(decorator){
		const decoration = decorator(this.el);
		this.el.parentNode.insertBefore(decoration, this.el);
		decoration.appendChild(this.el);
		return decoration;
	}

	/**
	 * Hide the input's content
	 */
	hide(){
		if(!this.isShowing)
			return;

		this.el.setAttribute("type", "password");
		// this.el.type = "password";
		this.isShowing = false;
	}

	/**
	 * Show the input's content
	 */
	show(){
		if(this.isShowing)
			return;

		this.el.setAttribute("type", "text");
		// this.el.type = "text";
		this.isShowing = true;
	}

	toggle(){
		if(this.isShowing)
			this.hide();
		else
			this.show();
	}
}
