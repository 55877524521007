import Accordino from "@js/libs/Accordino";
import { arrayFromGenerator } from "../utils";

const openedClass = "accordino__question--opened";

/**
 * @param {Element|HTMLAnchorElement} el
 */
const handleSubAccordino = el => {
	const label = el.querySelector("[data-closed]");
	const altLabel = el.querySelector("[data-opened]");

	if(label === null){
		console.error("[modules/accordino @ handleSubAccordino] label is null", el);
		return;
	}

	if(altLabel === null){
		console.error(
			"[modules/accordino @ handleSubAccordino] altLabel is null",
			el,
			label
		);
		return;
	}

	const show = span => {
		span.removeAttribute("hidden");
		span.setAttribute("aria-hidden", "false");
		el.querySelector(".accordino__question")
			.setAttribute("title", span.textContent);
	};

	const hide = span => {
		span.setAttribute("hidden", "hidden");
		span.setAttribute("aria-hidden", "true");
	};

	el.querySelector(".accordino__question").addEventListener("click", e => {
		e.preventDefault();
		e.stopPropagation();

		const wasOpen = label.hasAttribute("hidden");

		if(wasOpen){
			hide(altLabel);
			show(label);
		}
		else{
			hide(label);
			show(altLabel);
		}
	});

	// Initial setup
	hide(altLabel);
	show(label);
};

export const handleFaq = () => {
	const elements = document.querySelectorAll(".accordino");
	const accordinos = arrayFromGenerator(function* (){
		for(const el of elements){
			try{ // create all accordinos
				yield new Accordino(el);
			}
			catch(e){ // ignore failed attempts
				console.error(e, el);
			}
		}
	});

	accordinos.forEach(accordino => {
		const isSub = accordino.el.classList.contains("m-accordino__sub");

		accordino.close();

		if(isSub)
			handleSubAccordino(accordino.el);
	}); // start all of them closed
};

export const setupAllAccordino = () => {
	handleFaq();
};
