import { handleAnchorList } from "@js/modules/domain/anchorsList";
import { handleDecorations } from "@js/modules/domain/conditionalDecorations";
import { setupAllFundingDomain } from "@js/modules/domain/funding";
import { setupAllTestResults } from "@js/modules/domain/testResults";
import { handleLoginModalDecoration } from "@js/modules/domain/loginModal";
import { setupTestPermisPage } from "@js/modules/domain/testPermis";

const loginIcon = () => {
	// const loginButton = document.querySelector(".login[href]");
	const loginButton = document.querySelector(".o-header__buttons .login[href]");
	if(!loginButton){
		console.error("[modules/domain @ loginIcon] loginButton is null");
		return;
	}

	const anchor = document.querySelector(".o-header__actions > a[href]");
	if(!anchor){
		console.warn("[modules/domain @ loginIcon] anchor is null");
		return;
	}

	const href = loginButton.getAttribute("href");
	anchor.setAttribute("href", href);

	if(loginButton.hasAttribute("target")){
		const target = loginButton.getAttribute("target");
		anchor.setAttribute("target", target);
	}
};

export const setupAllDomainSpecificLogic = () => {
	loginIcon();
	handleLoginModalDecoration();
	handleAnchorList();
	handleDecorations();
	setupAllFundingDomain();
	setupAllTestResults();
	setupTestPermisPage();
};
