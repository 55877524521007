const fieldErrorClass = "error";


/**
 * Setup the field's validity handling
 * @param {Element} el
 */
const setupFieldValidity = el => {
	const shouldUseError = !el.matches("input[type='checkbox'], input[type='radio']");

	if(shouldUseError){
		const error = document.createElement("p");
		error.classList.add("a-error", "a-error--empty");
		error.setAttribute("aria-live", "assertive");
		el.parentNode.insertBefore(error, el.nextSibling);
	}


	el.addEventListener("invalid", e => {
		el.classList.add(fieldErrorClass);

		if(shouldUseError){
			const error = el.nextSibling;
			const { validationMessage } = el;
			error.classList.remove("a-error--empty");
			error.textContent = validationMessage;
		}
	});

	[
		"change",
		"input",
	].forEach(event => el.addEventListener(event, e => {
		el.classList.remove(fieldErrorClass);

		if(shouldUseError){
			const error = el.nextSibling;
			error.classList.add("a-error--empty");
			error.textContent = "";
		}
	}));
};

/**
 * Setup the validity API on the form
 * @param {Element|HTMLFormElement} form
 */
const setupValidityApi = form => {
	for(const field of form.elements)
		setupFieldValidity(field);
};

export const setupAllFormValidation = () => {
	document.querySelectorAll(".webform-submission-form")
		.forEach(setupValidityApi);
};
