export const setupMenuItems = () => {
	const header = document.querySelector(".o-header");
	header.querySelectorAll(".m-nav-main__item:not(.m-nav-main__item--parent), .m-nav-main__sub-item:not(.m-nav-main__sub-item--parent)")
		.forEach(el => { // handle click on item to delegate to the underlying link
			const clickable = el.querySelector(".m-nav-main__link, .m-nav-main__sub-link");

			if(clickable !== null){
				el.addEventListener("click", e => {
					if(e.target === clickable)
						return;

					clickable.click();
				});
			}
		});

	const expandedClass = "m-nav-main__item--expanded";
	const collapsedClass = "m-nav-main__item--collapsed";
	const subOpenClass = "m-nav-main__sub--open";
	header.querySelectorAll(".m-nav-main__item--parent")
		.forEach(el => { // handle opening/closing the submenus
			const sub = el.querySelector(".m-nav-main__sub");

			el.addEventListener("click", e => {
				if(e.target === el)
					e.preventDefault();

				if(el.classList.contains(expandedClass)){ // was expanded
					el.classList.remove(expandedClass);
					el.classList.add(collapsedClass);
					sub.classList.remove(subOpenClass);
				}
				else{ // was collapsed
					el.classList.remove(collapsedClass);
					el.classList.add(expandedClass);
					sub.classList.add(subOpenClass);
				}
			});

			if(el.classList.contains(expandedClass))
				el.click(); // Initial slide up
		});
};


export const setupAllMenu = () => {
	setupMenuItems();
};
