import { clearStateStorage } from "../persistence";

/**
 * Add Steppino specific handling for the permis step
 * @param {SteppinoSwiperController} controller
 * @param {object} elements
 * @param {Element} elements.permisEl
 * @param {Element} elements.ageEl
 */
export const handlePermis = (controller, { permisEl, ageEl }) => {
	const { steppino } = controller;
	const stepName = "permis";
	const input = permisEl.querySelector("input");

	if(!input){
		console.error("[modules/domain/funding/form/steps @ handlePermis] input is null", permisEl);
		return;
	}

	steppino.setStepWhenCleanup(stepName, () => {
		clearStateStorage();
		// Deselect
		permisEl.querySelectorAll(".m-cards__card--active")
			.forEach(el => el.classList.remove("m-cards__card--active"));

		if(input)
			input.value = "";

		const btn = permisEl.querySelector(".a-btn");
		if(btn)
			btn.setAttribute("aria-disabled", "true");

		return true;
	});
};
