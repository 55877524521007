import { ScrollSpy } from "../libs/ScrollSpy";
import {
	copyToClipboard,
	signalCopyFailure,
	signalCopySuccess,
} from "./clipboard";

export default class TableOfContentsBlock{
	/**
	 * @inheritDoc
	 * @override
	 */
	constructor(el){
		this.el = el;

		this.anchors = Array.from(this.el.querySelectorAll("a[href].m-toc_link, .m-toc_link > a[href]"));

		/**
		 * @type {HTMLAnchorElement}
		 */
		this.copyLink = this.el.querySelector("a[href].m-toc_copyUrl, .m-toc_copyUrl > a[href]");

		this.scrollSpy = new ScrollSpy({
			anchors: this.anchors,
			autoInit: false,
		});

		this.onCopyClick = this.onCopyClick.bind(this);
		this.copyUrlToClipboard = this.copyUrlToClipboard.bind(this);
	}

	/**
	 * @inheritDoc
	 * @override
	 * @hook init
	 */
	init(){
		this.copyLink.addEventListener("click", this.onCopyClick);
	}

	/**
	 * @inheritDoc
	 * @override
	 * @hook destroy
	 */
	destroy(){
		this.copyLink.removeEventListener("click", this.onCopyClick);
	}

	/**
	 * @inheritDoc
	 * @override
	 * @hook initDesktop
	 */
	initDesktop(){
		this.scrollSpy.init();
	}

	/**
	 * @inheritDoc
	 * @override
	 * @hook initDesktop
	 */
	destroyDesktop(){
		this.scrollSpy.destroy();
	}

	onCopyClick(e){
		e.preventDefault();

		this.copyUrlToClipboard();
	}

	async copyUrlToClipboard(){
		// eslint-disable-next-line no-restricted-globals
		const url = `${location.origin}${location.pathname}`;
		const didCopy = await copyToClipboard(url);

		if(didCopy)
			signalCopySuccess(this.copyLink, this.copyLink);

		else
			signalCopyFailure(this.copyLink, this.copyLink);
	}
}
