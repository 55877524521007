import { getHash } from "@js/modules/queryString";
import makeBound from "bounds.js";


/**
 * @typedef {object} AnchorItem
 * @property {Element} target - The pointed element
 * @property {Element} anchor - The anchor that points to that element
 * @property {string} id - The ID of the pointed element
 */

/**
 * @typedef {Map<string, AnchorItem>} AnchorMapping
 */


const activeClass = "m-anchors__anchor--active";
// const activeClass = "active";

/**
 * Get the ID from an anchor
 * @param {Element} anchor
 * @returns {string|null}
 */
const idFromAnchor = anchor => {
	const href = anchor.getAttribute("href");

	if(typeof href !== "string" || !href.startsWith("#"))
		return null;

	return href.substr(1);
};

/**
 * Get the map of hash to element pointed by an anchor from the anchor list
 * @param {Element} anchorList
 * @returns {AnchorMapping}
 */
const anchorsMap = anchorList => {
	/**
	 * @type {AnchorMapping}
	 */
	const anchors = new Map();

	anchorList.querySelectorAll(".m-anchors__anchor")
		.forEach(anchor => {
			const id = idFromAnchor(anchor);

			if(typeof id === "string"){
				const target = document.getElementById(id);
				anchors.set(id, {
					anchor,
					target,
					id,
				});
			}
		});

	return anchors;
};

/**
 * Get all the similar anchors
 * @param {Element} anchor
 * @returns {Element[]}
 */
const getAllSimilarAnchors = anchor => {
	const id = idFromAnchor(anchor);

	if(id === null)
		return [anchor];

	return Array.from(document.querySelectorAll(`.m-anchors__anchor[href="#${id}"]`));
};

/**
 * Make the given anchor inactive
 * @param {Element} anchor
 */
const makeAnchorInactive = anchor => {
	getAllSimilarAnchors(anchor)
		.forEach(el => el.classList.remove(activeClass));
};

/**
 * Make the anchor active (and the already active anchors inactive)
 * @param {Element} anchor
 */
const makeAnchorActive = anchor => {
	const id = idFromAnchor(anchor);
	if(id === null)
		return;

	/*document.querySelectorAll(`.${activeClass}`)
		.forEach(makeAnchorInactive); // remove the old ones*/

	// Make all anchors with the same ID active
	getAllSimilarAnchors(anchor)
		.forEach(el => el.classList.add(activeClass));
};

/**
 * Go to the anchor corresponding to the hash
 * @param {AnchorMapping} anchors - The anchors mapping
 * @param {string} hash - The hash of the desired anchor
 */
const goToAnchor = (anchors, hash) => {
	const anchorItem = anchors.get(hash);
	if(typeof anchorItem === "undefined"){
		console.error("[domain/anchorsList] Tried to go to an undefined anchor");
		return;
	}

	const { target, anchor } = anchorItem;
	makeAnchorActive(anchor);

	if(target && typeof target.scrollIntoView !== "undefined")
		target.scrollIntoView({ block: "nearest" });
};

/**
 * Go to the current anchor (or make the first one active if none)
 * @param {AnchorMapping} anchors - The anchors mapping
 */
const goToCurrentAnchor = anchors => {
	const hash = getHash();

	if(anchors.has(hash)) // TODO: Debug this branch
		goToAnchor(anchors, hash);
	else{
		const [ firstItem ] = anchors.values();
		const { anchor } = firstItem;
		makeAnchorActive(anchor);
	}
};


/**
 * Handle the links duplication
 * @param {AnchorMapping} anchors - The mapping of IDs to anchor entries
 * @param {Element} sourceList - The source list of links
 * @param {Element} wrapper - The parent list to which it needs to be appended
 */
const duplicateLinks = (
	anchors,
	sourceList,
	wrapper
) => {
	sourceList.querySelectorAll(".m-anchors__anchor")
		.forEach(anchor => {
			const newAnchor = anchor.cloneNode(true);
			wrapper.appendChild(newAnchor);
		});
};

/**
 * Create the duplicate menu in the header for when scrolled
 * @param {AnchorMapping} anchors
 * @param {Element} sourceList
 * @returns {Element}
 */
const createHeaderDuplicate = (anchors, sourceList) => {
	const wrapper = document.createElement("div");
	wrapper.classList.add(
		"m-anchors",
		"m-anchors--nav",
		"m-anchors--hidden"
	);

	[
		["role", "nav"],
		["aria-hidden", "true"],
		["aria-disabled", "true"],
		["disabled", "disabled"],
		["hidden", "hidden"],
	].forEach(([key, value]) => wrapper.setAttribute(key, value));

	duplicateLinks(
		anchors,
		sourceList,
		wrapper
	);

	const header = document.querySelector("header");
	// insert it after the header
	header.parentElement.insertBefore(wrapper, header.nextSibling);

	return wrapper;
};

/**
 * Spy the scroll
 * @param {AnchorMapping} anchors
 * @returns {bounds.Boundary}
 */
const spyScroll = anchors => {
	const anchoredElements = [];

	// eslint-disable-next-line keyword-spacing
	for(const { id } of anchors.values()){ // Get the anchors
		const el = document.getElementById(id);
		if(el !== null)
			anchoredElements.push(el);
	}

	const boundary = makeBound({
		threshold: 0.2,
		margins: { bottom: 75 },
	});

	anchoredElements.forEach(el => {
		const id = el.getAttribute("id");
		const { anchor } = anchors.get(id);

		const onEnter = () => {
			makeAnchorActive(anchor);
		};

		const onLeave = () => {
			makeAnchorInactive(anchor);
		};


		boundary.watch(
			el,
			onEnter,
			onLeave
		);
	});

	return boundary;
};

/**
 * Manage the header duplicate's transition
 * @param {Element} wrapper - The duplicate's wrapper
 * @param {Element} sourceList - The original anchors list element
 */
const headerDuplicateTransition = (wrapper, sourceList) => {
	// Margin of -100 to be almost like the header's height but leave some space for transition
	const boundary = makeBound({ margins: { top: -100 } });

	const onSourceEnter = () => {
		wrapper.classList.add("m-anchors--hidden");

		// Accessibility
		[
			["aria-hidden", "true"],
			["aria-disabled", "true"],
			["disabled", "disabled"],
			["hidden", "hidden"],
		].forEach(([attr, value]) => wrapper.setAttribute(attr, value));
	};

	const onSourceLeave = () => {
		// Only makes active if current scroll is below the element
		const { y, top } = sourceList.getBoundingClientRect();
		const scrollIsBelowList = (y && y < 0) || (top && top < 0); // using Y is default, top is for stoopid Edge

		if(scrollIsBelowList){ // hide only if below the list
			wrapper.classList.remove("m-anchors--hidden");

			// Accessibility
			[
				"disabled",
				"hidden",
			].forEach(attr => wrapper.removeAttribute(attr));

			[
				["aria-hidden", "false"],
				["aria-disabled", "false"],
			].forEach(([attr, value]) => wrapper.setAttribute(attr, value));
		}
	};

	boundary.watch(
		sourceList,
		onSourceEnter,
		onSourceLeave
	);

	//TODO: Make it appear on load on Edge
};

const addClasses = () => {
	document.querySelectorAll(".o-top")
		.forEach(topBlock => topBlock.classList.add("o-top--with-anchors"));
};

export const handleAnchorList = () => {
	const anchorList = document.querySelector(".m-anchors");

	if(anchorList === null)
		return;

	addClasses();
	const anchors = anchorsMap(anchorList);
	const wrapper = createHeaderDuplicate(anchors, anchorList);
	headerDuplicateTransition(wrapper, anchorList);
	spyScroll(anchors);
	goToCurrentAnchor(anchors);
};
