import { slideUp, slideDown } from "es6-slide-up-down";
import { easeInOutCubic } from "es6-easings";

const duration = 600;
const easing = easeInOutCubic;
const slideOptions = {
	duration,
	easing,
};

export default class Accordino{
	constructor(el){
		// Check element
		if(el instanceof HTMLElement)
			this.el = el;

		else
			this.el = document.querySelector(el);

		if(!this.el)
			throw new Error("No valid accordions found. Sorry bro.");


		// Get dom
		this.question = this.el.querySelector(".accordino__question");
		this.answer = this.el.querySelector(".accordino__answer");

		// Init listeners
		this.question.addEventListener("click", () => {
			if(this.question.classList.contains("accordino__question--opened"))
				this.close();

			else
				this.open();
		});
	}

	open(){
		this.question.classList.add("accordino__question--opened");
		slideDown(this.answer, slideOptions);
	}

	close(){
		this.question.classList.remove("accordino__question--opened");
		slideUp(this.answer, slideOptions);
	}
}
