import { Modalino } from "@js/libs/Modalino";
import { qsHas } from "@js/modules/queryString";

const isTestPage = () => {
	return [
		"/tester-notre-outil-en-ligne-gratuitement",
		"/node/10",
	].includes(location.pathname) || document.querySelector(".m-main--10");
};

const shouldPopOpenLoginModal = () => qsHas("login")/* && qsIs("login", "open")*/;

const shouldPopOpenTestModal = () => isTestPage() && qsHas("test");

const loginModal = () => {
	const el = document.querySelector("#login.m-modal");

	if(el === null){
		console.error("[modules/modal @ login] No el");
		return;
	}

	const modalino = new Modalino(el);

	if(shouldPopOpenLoginModal())
		modalino.show();
};

const testModal = () => {
	if(!isTestPage())
		return;

	const el = document.querySelector("#test.m-modal");

	if(el === null){
		console.error("[modules/modal @ test] No el");
		return;
	}

	const iframe = el.querySelector("iframe");

	if(iframe === null){
		console.error("[modules/modal @ test] No iframe");
		return;
	}

	const modalino = new Modalino(el);
	modalino.onClose(() => iframe.setAttribute("src", "/"));

	const buttons = document.querySelectorAll(".m-cards--test .m-cards__card .a-btn[href]");
	buttons.forEach(btn => {
		const href = btn.getAttribute("href");
		btn.addEventListener("click", e => {
			e.preventDefault();
			iframe.setAttribute("src", href);
			modalino.show();
		});
	});
};


export const setupAllModals = () => {
	loginModal();
	// testModal();
};
