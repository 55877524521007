import { Permis } from "../enums";

export const showAlertsFor = permis => {
	// Show the bike specific message(s)
	// if(permis === Permis.MOTO){
	const alerts = document.querySelectorAll(".m-funding-form__alert[data-alert]");

	alerts.forEach(alert => {
		alert.removeAttribute("hidden");
		alert.setAttribute("aria-hidden", "false");
	});
	// }
};

export const hideAlertsFor = permis => {
	// Hide back the bike specific message(s)
	const alerts = document.querySelectorAll(".m-funding-form__alert[data-alert]");
	alerts.forEach(alert => {
		alert.setAttribute("hidden", "hidden");
		alert.setAttribute("aria-hidden", "true");
	});
};
